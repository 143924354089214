import React from 'react'
import './Card.css'
import Counter from './../Counter/Counter';
import PlayButton from '../Button/Play_button';
import {FormattedMessage} from 'react-intl'
import {showBubbleContests} from './showBubble'
import {config} from './../../utils/domain_config'
class Card extends React.Component {
    onExpired = () => {
        let el = document.getElementById(this.props.id)
        el.classList.add('disable-click-card')
    }
    render(){
        let img_src = `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${this.props.img}`    
        return(
            
            <div className="custom-card" id={this.props.id} onClick={this.props.onClick}>
                
                 { showBubbleContests[config.subDomain] && showBubbleContests[config.subDomain].includes(this.props.title) ?
                <div className="buuble-cart">
                <div className="bubble">
                    <span className="bubble-product1">
                        <FormattedMessage id="Hurry!" defaultMessage="Hurry!" />
                    </span>
                    <span className="bubble-product2">
                        <FormattedMessage id="Win it now!" defaultMessage="Win it now!" />
                    </span>
                    </div>
                </div>:null}

                <div className="img-card">
                    <img src={img_src} alt={this.props.title} />
                </div>
                                 
                <div className="custom-card-title">
                    {this.props.title}
                </div>
                
                <div className="custom-card-win-score">
                    <span><i className="fas fa-star"></i></span>
                    <FormattedMessage id="Score to Beat" defaultMessage="Score to Beat" />:
                </div>
                
                <div className="custom-card-score">
                    <span><i className="fas fa-check" style={{color: 'green', marginRight: '2px'}}></i></span>
                    <span> {this.props.maxscore}
                    {" "}<FormattedMessage id="pts" defaultMessage="pts" />
                    </span>
                </div>
                
                <div className="custom-card-play-button">
                   <PlayButton/>
                    <div className="counter-wrapper">
                        <Counter expiryDate={this.props.end_date} whenExpired={this.onExpired} />
                    </div>
                   </div>
            </div>
        );
    }
}

export default Card;