import React from 'react'
import './Input.css'

const Input = (props) => {
    return(
    <div className="custom-input-container">
        <span>
            <i className={props.icon}></i>
        </span>
        <input onKeyDown={props.onKeyDown} name={props.name} className="custom-input" style={props.validate === 1 ? {borderColor: 'green'} : props.validate === 0 ? {borderColor: 'red'} : null} pattern={props.pattern} type={props.type} placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
        {props.validate !== -1 ? <span className="validate-input" style={props.validate === 1 ? {color: 'green', visibility: 'visible'} : props.validate === 0 ? {color: 'red', visibility: 'visible'} : {visibility: 'hidden'}}>
            {props.validate === 1 ? <i className="fa fa-check validate-input-icon"></i> : props.validate === 0 ? <i className="fa fa-times validate-input-icon"></i> : null}
        </span> : null}
    </div>
    )
}

export default Input;