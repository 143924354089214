import React from 'react'
import './Sidebar.css'
import dp from './../../assets/images/dp.png'
import {FormattedMessage} from 'react-intl'
import { config } from '../../utils/domain_config'

const Sidebar = (props) => {
    let SidebarClass = "custom-sidebar"
    let imagehref = dp
    let winnerLink = '#'
    if(props.show){
        SidebarClass = "custom-sidebar open-sidebar"
    }
    if(props.image !== null){
        imagehref = props.image
    }
    if(config.subDomain === "gr"){
        winnerLink = 'https://n.funiwin.com/gr/tnc-funiwin?offer=1&_next=winner.html';
    }
    else if(config.subDomain === "gr-mobimilia"){
        winnerLink = 'https://n.funiwin.com/gr/tnc-lilymobile-funiwin?offer=1&_next=winner.html&_extracted=d80949d9ed284d60be2e324bff5242ed&_next=winner.html&_extracted=d80949d9ed284d60be2e324bff5242ed&';
    }
    return(
        <>
        <nav className={SidebarClass}>
            <ul>
                <li className="close-sidebar-button">
                    <span onClick={props.close}><i className="fa fa-times"></i></span>
                </li>
                <li className="custom-image-sidebar" onClick={props.onClick} id="profile">
                    <div style={{position: 'relative', padding: '5px 10px'}}>
                        <img src={imagehref} alt="Logo" />
                        <img src={require('./../../assets/images/verificationico.svg')} alt="" className="tick-dp" />
                    </div>
                    <div>
                        <span>{props.user_name !== null && props.user_name.length > 10 ? `${props.user_name.substr(0,9)}...` : props.user_name}</span>
                        <span>
                            <FormattedMessage id="Level" defaultMessage="Level" />
                            {" "}{props.level}
                        </span>
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={props.onClick} id="home">
                        <i className="fas fa-store sidebar-icon"></i> 
                        <span style={{marginLeft: '24px'}}>
                            <FormattedMessage id="Games" defaultMessage="Games" />    
                        </span> 
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={props.onClick} id="profile">
                        <i className="fas fa-user sidebar-icon"></i>
                        <span>
                            <FormattedMessage id="Profile" defaultMessage="Profile" />
                        </span>
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={props.onClick} id="scores">
                        <i className="fas fa-star sidebar-icon"></i>
                        <span style={{marginLeft: '26px'}}>
                        <FormattedMessage id="Scores" defaultMessage="Scores" />
                        </span>
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={props.onClick} id="faqs">
                        <i className="fas fa-question sidebar-icon"></i>
                        <span style={{marginLeft: '34px'}}>
                        <FormattedMessage id="Faq" defaultMessage="Faq" />
                        </span>
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={props.onClick} id="contact">
                        <i className="fas fa-envelope sidebar-icon"></i>
                        <span style={{marginLeft: '31px'}}>
                        <FormattedMessage id="Contact Us" defaultMessage="Contact Us" />
                        </span>
                    </div>
                </li>
                <li className="custom-li-button">
                    <div onClick={() => {window.open(winnerLink)}}>
                        <i className="fas fa-trophy sidebar-icon"></i>
                        <span style={{marginLeft: '31px'}}>
                        <FormattedMessage id="Previous Winners" defaultMessage="Previous Winners" />
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
        </>
    )
}

export default Sidebar;
