import React from 'react'
import './Splash.css'
import logo from './../../assets/images/funiwinlogo.svg'
import {connect} from 'react-redux'
import { sendData } from '../../analytics/senddata'

class Splash extends React.Component {

    state = {
        redirect: false
    }

    componentDidMount(){
        this.redirect = setTimeout(() => {
            this.setState({redirect: true})
        }, 2500)
    }

    componentDidUpdate(){
        if(this.state.redirect){
            if(this.props.isLoggedIn === true){
                sendData('', '', '', 'Accessed', { method: 'uid', status: 'success'});
                if(this.props.doesAccountExist === true) this.props.history.push("/home")
                else this.props.history.push("/orientation")
            }
            if(this.props.isLoggedIn === false){
                this.props.history.push("/orientation")
            }
        }
    }

    componentWillUnmount(){
        clearTimeout(this.afterAnimation)
        clearTimeout(this.redirect)
    }
    render(){
        return(
            <div className="splash_page">
                <div className="splash-screen-main">
                    <div className="splash-logo">
                        <img src={logo} alt="funiwin" />
                    </div>
                    <div className="splash-loader">
                        <i className="fa fa-spinner fa-pulse"></i>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
        uid: state.auth.uid
    }
} 

export default connect(mapStateToProps, null)(Splash);