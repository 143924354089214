export const unsubLinks = {
    'demo': '#',
    'gr': 'http://n.funiwin.com/gr/tnc-funiwin?offer=1&_next=contact.html',
    'za': 'http://ads-za-yola.sam-media.com/unsubscribe/za1?country=jo&lang=en',
    'gr-mobimilia': 'https://n.funiwin.com/gr/tnc-lilymobile-funiwin?offer=1&_next=contact.html&_extracted=d80949d9ed284d60be2e324bff5242ed&_next=contact.html&_extracted=d80949d9ed284d60be2e324bff5242ed&'
}

export const tnCLinks = {
    'demo': '#',
    'gr': 'https://n.funiwin.com/gr/tnc-funiwin?device=pc&offer=1',
    'gr-mobimilia': 'https://n.funiwin.com/gr/tnc-lilymobile-funiwin?offer=1&_next=general_conditions.html&_extracted=d80949d9ed284d60be2e324bff5242ed&_next=general_conditions.html&_extracted=d80949d9ed284d60be2e324bff5242ed&'
}

export const privacyPolicyLinks = {
    'demo': '#',
    'gr': 'http://n.funiwin.com/gr/tnc-funiwin?offer=1&device=pc&_next=privacy_policy.html&_extracted=a87e968fc454467e9206e4e87c4d004a&',
    'gr-mobimilia': 'https://n.funiwin.com/gr/tnc-lilymobile-funiwin?offer=1&_next=privacy_policy.html&_extracted=d80949d9ed284d60be2e324bff5242ed&_next=privacy_policy.html&_extracted=d80949d9ed284d60be2e324bff5242ed&'
}