import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'

export const verifyUser = (uid) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/users/getUser?uid=${uid}`)
        .then((response) => {
            if(response.data.verify === true && sessionStorage.getItem('accountexist') !== true){
                sessionStorage.setItem('accountexist', true)
            }
            else if(response.data.verify !== true) sessionStorage.removeItem('accountexist')
            if(response.data.data !== null){
                dispatch(userInfo(response.data.verify, response.data.data[0]))
            }
            else {
                dispatch(userInfo(response.data.verify, null))
            }
        })
        .catch((e) => console.log(e))
    }
}


const userInfo = (verify, data) => {
    return{
        type: actiontypes.VERIFY_ACCOUNT,
        verify: verify,
        data: data
    }
}

export const updateUser = (data) => {
    return{
        type: actiontypes.UPDATE_ACCOUNT,
        payload: data
    }
}