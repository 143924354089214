import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'
const authStart = () => {
    return{
        type: actiontypes.AUTH_START
    }
}

const authSuccess = (data) => {
    return{
        type: actiontypes.AUTH_SUCCESS,
        payload: data
    }
}

const authSuccessMsisdn = (data) => {
    return{
        type: actiontypes.AUTH_SUCCESS_MSISDN,
        payload: data
    }
}

const authFail = (error) => {
    return{
        type: actiontypes.AUTH_FAIL,
        payload: error
    }
}

export const authMsisdn = (data) => {
    return dispatch => {
        dispatch(authStart())
        dispatch(authSuccessMsisdn(data))
    }
}

export const auth = ({uid, msisdn, staging}) => {
    return dispatch => {
        dispatch(authStart());
        const url = new URL(`${BASE_API_URL}/checkAccess`)
        if(staging && uid){
            sessionStorage.setItem("staging", true)
            url.searchParams.append("token", uid)
            url.searchParams.append("type", "staging")
        }
        else if(msisdn){
            url.searchParams.append("token", msisdn)
            url.searchParams.append("type", "msisdn")
        }
        else if(uid){
            url.searchParams.append("token", uid)
            url.searchParams.append("type", "uid")
        }
        else{
            dispatch(authFail())
        }
        url.searchParams.append("domain", window.location.hostname)
        axios.get(url.href)
        .then((response) => {
            if(response.data.success){
                const payload = response.data.payload
                let obj = {...payload, uid: uid || payload.token || payload.rockmanId}
                if(sessionStorage.getItem('uid') === null || sessionStorage.getItem('uid') !== uid){
                    sessionStorage.setItem('uid', obj.uid)
                    if(response.data.payload.hasOwnProperty('rockmanId')){
                        sessionStorage.setItem('rockmanId', payload.rockmanId)
                    }
                }
                dispatch(authSuccess(obj))
            } 
            else dispatch(authFail(response.data))
        })
        .catch((error) => {
            dispatch(authFail(error.data))
        })
    }
}