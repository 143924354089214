export const faq_data = [
    {
        id: "1",
        question: 'How do the games work?',
        answer: [
        '\u2022 Click the button “Play to win” below the prize you want to beat the best score to win.', 
        '\u2022 Answer the questions by clicking on the correct answers.\n',
        '\u2022 Once you finish all the questions you will see your score.']
    },
    {
        id: "2",
        question: 'How many times can I play a game?',
        answer: 'You can play each game as many times as you wish! Until the tournament expires, you have the ability to win with your score if it is the best!'
    },
    {
        id: "3",
        question: 'How to win the prizes?',
        answer: 'The best score player wins the tournament! Scores are tracked until the competition runs out, at the end of the competition, the player with the best score for that competition wins the prize.'
    },
    {
        id: "4",
        question: 'If I win a prize, how do I receive it?',
        answer: 'If you are the winner of a competition, we will contact you on your mobile number.'
    },
    {
        id: "5",
        question: 'How do I cancel my subscription?',
        answer: 'To cancel your subscription, please go to Contact us in the menu, and unsubscribe from there. Please mention the reason if you intend to unsubscribe, this way it will help us to continue to improve our service to satisfy our customers, we thank you for your time.'
    },
    {
        id: "6",
        question: 'How did I get subscribed to Funiwin?',
        answer: 'By coming across one of our promotional pages and clicking on the subscribe button. We confirm your enrolment to our services by sending you an SMS message to your mobile phone.'
    },
    {
        id: "7",
        question: 'Is there a charge for using Funiwin?',
        answer: 'You are charged the rate that is mentioned on the promotional page that you visited when you subscribed to the service. You can also find it in the welcome message you received upon subscribing and in the monthly SMS reminder you receive from us.'
    },
    {
        id: "8",
        question: 'How do I reach out to Customer Care?',
        answer: 'Our dedicated Customer Care virtual assistant will be happy to answer all your questions and solve any issues. You may click on the icon of customer care on the home page, it will redirect you to whatsapp business account and there you will be helped 24/7 for questions, service status check, and giving feedback.'
    }
]