import {combineReducers} from 'redux'
import AuthReducer from './AuthReducer'
import ContestReducer from './ContestReducer'
import ScoreReducer from './ScoreReducer'
import AlertReducer from './AlertReducer'
import GameReducer from './GameReducer'
import FeedbackReducer from './FeedbackReducer'

const reducer = combineReducers({
    auth: AuthReducer,
    contest: ContestReducer,
    score: ScoreReducer,
    alert: AlertReducer,
    question: GameReducer,
    feedback: FeedbackReducer
})

export default reducer;