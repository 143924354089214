import React from 'react'
import {connect} from 'react-redux'
import CombinedHeaderandSidebar from '../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar'
import './Scoreboard.css'
import Loader from './../../components/Loader/Loader'
import { FormattedMessage } from 'react-intl'
import { sendData } from '../../analytics/senddata'


class Scoreboard extends React.Component{
   /* state = {
        scores: [],
        loaded: false,
    }*/
    
    componentDidMount(){
        window.scrollTo(0,0);
        sendData('','','','Viewed',{page: 'Scores'});
    }

    componentDidUpdate(){
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true){
            this.props.history.push("/")
        }
    }

    ScoreData=()=>{
        if(this.props.scores_data){
            let all_scores = this.props.scores_data.map((item) => {
                return{
                    dc_id: item.domain_contest_id,
                    prize_name: item.prize_name,
                    prize_img: `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${item.prize_img}`,
                    played_at: this.formatDate(item.played_at),
                    score: item.score
                }
            })
            return all_scores;
        } else{
            return []
        }
    }

    componentWillUnmount(){
    }

    formatDate = (t) => {
        let d = new Date(t);
        return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`
    }

    playGame = (id) => {
        
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true){
            this.props.history.push("/login")
        }
        else {
            this.props.history.push({
                pathname: '/games',
                state: {index: id}
            })
        }
    }
    render(){
        const scores =this.ScoreData();
        if(!this.props.scores_data){
            return(
                <>
                <CombinedHeaderandSidebar />
                <Loader name="scores" />    
                </>
            )
        }
        return(
            <div className="myscore-main-container">
            <CombinedHeaderandSidebar />
            <div className="myscore-custom-card">
                <div className="top-score-custom-card">
                    <div className="myscore-header">
                        <span>
                            <FormattedMessage id="My scores" defaultMessage="My scores" />
                        </span>
                    </div>
                {scores.length > 0 ?
                    <div className="custom-table">
                    {scores.map((item, index)=>{
                        return(
                            <div key={item.dc_id}>
                            <div className="custom-score-card" style={ index === scores.length - 1 ? { borderBottom: "none" } : null}>
                                <div className="product-image">
                                    <img src={item.prize_img} alt="..." />
                                </div>
                                <div className="details">
                                    <div className="custom-product-name">
                                        <span>{item.prize_name}</span>
                                    </div>
                                    <div className="your-score">
                                        <span><FormattedMessage id="Your Score" defaultMessage="Your Score" />:</span>
                                        <span>{item.score} <FormattedMessage id="pts" defaultMessage="pts" /></span>
                                    </div>
                                    <div className="myscore-button"  onClick={()=>this.playGame(item.dc_id)}>
                                        <span className="top-10-custom-buttons">                        
                                            <FormattedMessage id="Retry" defaultMessage="Retry" />
                                        </span>
                                        <span className="top-10-custom-icon">
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>            
                            </div>
                                )
                            })}
                        </div>:
                        <div className="noscore-heading-customs">
                            <div className="no-score-card">
                                <span>
                                    <FormattedMessage id="No Scores Available" defaultMessage="No Scores Available" />
                                </span>
                            </div>
                        </div>}
                   </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
        scores_data: state.score.scores_data,
        contest_data: state.contest.contest_data,
    }
}

export default connect(mapStateToProps, null)(Scoreboard);