import React from "react";
import "./Orientation.css";
import {connect} from 'react-redux'
import hand from './../../assets/images/orientation/hand-icon.svg'
import play1 from './../../assets/images/orientation/play-1.svg'
import play2 from './../../assets/images/orientation/play-2.svg'
import play3 from './../../assets/images/orientation/play-3.svg'
import  claim1 from './../../assets/images/orientation/Claim-icon-1.svg'
import  claim2 from './../../assets/images/orientation/Claim-icon-2.svg'
import  claim3 from './../../assets/images/orientation/Claim-icon-3.svg'
import {FormattedMessage} from 'react-intl'
import { sendData } from "../../analytics/senddata";
class Orientation extends React.Component {
  state = {
    index: 0
  };

  componentDidMount(){
    sendData('','','','Viewed',{page: 'Orientation Page'});
  }

  onClickHandler = e => {
    this.setState(prevState => ({
      index: prevState.index + 1
    }));
  };

  onSubmitHandler = e => {
    if(this.props.isLoggedIn === true && this.props.doesAccountExist === false){
      this.props.history.push("/account")
    }
    else this.props.history.push("/home")
  }

  OrientationScreen1 = (
    <div className="o-page">
    
      <div className="o-dots">
        <span className="o-dot show-dot"></span>
        <span className="o-dot"></span>
        <span className="o-dot"></span>
      </div>
      <div className="o-next-btn">
        <button onClick={this.onClickHandler}>
        <FormattedMessage id="Next" defaultMessage="Next" />
        </button>
      </div>
      <div className="o-contents">
        <div className="o-select-game-box">
          <div> <div className="o-select-game-box-content">1</div></div>
          <div> <div className="o-select-game-box-content">2</div></div>
          <div> <div className="o-select-game-box-content">3</div></div>
          <div> <div className="o-select-game-box-content o-active-play">4</div></div>
        </div>
        <div className="o-select-game-box-text"> 
        <FormattedMessage id="Choose a prize" defaultMessage="Choose a prize" />
        </div>
        <div className="o-select-game-box-sub">
        <FormattedMessage id="From a variety of items you may like" defaultMessage="From a variety of items you may like" />
        </div>
        <div className="o-select-hand">
          <img src={hand} alt="" />
        </div>
      </div>
    </div>
  );

  OrientationScreen2 = (
    <div className="o-page">
      <div className="o-next-btn">
        <button onClick={this.onClickHandler}>
        <FormattedMessage id="Next" defaultMessage="Next" />
        </button>
      </div>
      <div className="o-dots">
        <span className="o-dot"></span>
        <span className="o-dot show-dot"></span>
        <span className="o-dot"></span>
      </div>
      <div className="o-contents">
        <div className="o-play-game-box">
          <img src={play2} alt="" />
          <img src={play1} alt="" />
          <img src={play3} alt="" />
        </div>
        <div className="o-select-game-box-text">
          <FormattedMessage id="Play" defaultMessage="Play" />
        </div>
        <div className="o-select-game-box-sub">
        <FormattedMessage id="Play a game and beat the best score" defaultMessage="Play a game &amp; beat the best score" />
        </div>
      </div>
    </div>
  );

  OrientationScreen3 = (
    <div className="o-page">
      <div className="o-get-started-btn">
        <button onClick={this.onSubmitHandler}>
        <FormattedMessage id="Get Started" defaultMessage="Get Started" />
        </button>
      </div>
      <div className="o-dots">
        <span className="o-dot"></span>
        <span className="o-dot"></span>
        <span className="o-dot show-dot"></span>
      </div>
      <div className="o-contents">
        <div className="o-claim-game-box">
          <img src={claim2} alt="" />
          <img src={claim1} alt="" />
          <img src={claim3} alt="" />
        </div>
        <div className="o-select-game-box-text">
        <FormattedMessage id="Win your prize!" defaultMessage="Win your prize!" />
        </div>
        <div className="o-select-game-box-sub"><FormattedMessage id="Once the tournament is over, winners are announced" defaultMessage="Once the tournament is over, winners are announced" /></div>
      </div>
    </div>
  );

  Screens = [
    this.OrientationScreen1,
    this.OrientationScreen2,
    this.OrientationScreen3
  ];

  render() {
    return <div>{this.Screens[this.state.index]}</div>;
  }
}

const mapStateToProps = state => {
  return{
      isLoggedIn: state.auth.isLoggedin,
      doesAccountExist: state.auth.doesAccountExist,
      uid: state.auth.uid
  }
} 

export default connect(mapStateToProps, null)(Orientation);