import React from 'react'
import Header from './../Header/Header'
import Sidebar from './../Sidebar/Sidebar'
import Backdrop from './../Backdrop/Backdrop'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Alert from '../Alert/Alert'
import CookieBanner from '../CookieBanner/CookieBanner'

class CombinedHeaderandSidebar extends React.Component{
    state = {
        SidebarOpen: false
    }

    toggleSidebarHandler = () => {
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true) this.props.history.push("/login")
        else this.setState((prevState) => {
            return {SidebarOpen: !prevState.SidebarOpen}
        })
    }

    closeSidebar = () => {
        this.setState({SidebarOpen: false})
    }

    onClickHandler = (e) => {
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true) this.props.history.push("/login")
        else this.props.history.push(`/${e.target.id}`)
    }

    render(){
        let firstname = null;
        let imageurl = null;
        let level_user = 0;
        if(this.props.user_data !== null){
            let {name, image, level} = this.props.user_data
            if(image !== null){
                if (image.indexOf('http://') !== -1 || image.indexOf('https://') !== -1){
                    imageurl = image
                } 
                else imageurl = `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${image}`
            }
            else imageurl = null
            firstname = name.split(' ')[0]
            level === null ? level_user = 1 : level_user = level
        }
        return(
            <>
            <CookieBanner />
            <Header style={this.props.style} toggleSidebar={this.toggleSidebarHandler} onClick={this.onClickHandler} />
            <Alert />
            <Sidebar show={this.state.SidebarOpen} close={this.closeSidebar} onClick={this.onClickHandler} user_name={firstname} image={imageurl} level={level_user} />
            {this.state.SidebarOpen ? <Backdrop close={this.closeSidebar} /> : null}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user_data: state.auth.user_data,
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
        uid: state.auth.uid
    }
}

export default connect(mapStateToProps, null)(withRouter(CombinedHeaderandSidebar));