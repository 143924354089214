const Homepage = {
    'Choose a prize': 'Επιλέξτε ένα έπαθλο',
    'Play and beat the best score': 'Παίξτε και σκοράρετε',
    'Win the prize!': 'Κερδίστε το έπαθλο!',
    'Play Now': 'Παίζω',
    'Play to Win': 'Παίζω Τώρα',
    'Start Now': 'Ξεκίνα τώρα',
    'Score to Beat': 'Νίκη',
    'Days': 'Ημέρες',
    'Hours': 'Ώρες',
    'Minutes': 'Λεπτά',
    'Seconds': 'δεύτερος',
    'pts': 'πόντοι',
    'Hurry!': 'Βιαστείτε!',
    'Win it now!': 'Κερδίστε το τώρα!',
    'Loading...': 'Φόρτωση...',
    'My contests': 'Οι διαγωνισμοί μου',
    'Timer': 'Λήγει',
}

const SplashScreen = {
    'Next': 'Επόμενο',
    'Get Started': 'Έναρξη',
    'Play': 'Παιχνίδι',
    'Play a game and beat the best score': 'Παίξτε ένα παιχνίδι & ξεπεράστε το σκορ',
    'From a variety of items you may like': 'Από μια ποικιλία αντικειμένων που μπορεί να σας αρέσουν',
    'Win your prize!': 'Κερδίστε το έπαθλό σας!',
    'Once the tournament is over, winners are announced': 'Μόλις ολοκληρωθεί το τουρνουά, ανακοινώνονται οι νικητές',
}


const LoginAccountPage = {
    'Welcome': 'Καλώς Ήρθατε',
    'To login, please enter your mobile number': 'Για σύνδεση, παρακαλούμε πληκτρολογήστε τον αριθμό του κινητού σας',
    'To continue, please signup using your name and email': 'Για να συνεχίσετε, εγγραφείτε χρησιμοποιώντας το όνομα και το email σας',
    'LOGIN': 'ΣΥΝΔΕΣΗ',
    'SIGN UP': 'ΕΓΓΡΑΦΗ',
    'SKIP FOR NOW': 'ΠΑΡΑΛΕΙΠΩ'
}

const Profile = {
    'My profile': 'Το προφίλ μου',
    'Your Name': 'Το Όνομά Σας',
    'Your Email': 'Το Email Σας',
    'Your Subscription Details': 'Τα Στοιχεία Συνδρομής Σας',
    'Mobile Number': 'Αριθμός Κινητού',
    'Subscribed on': 'Έγινε εγγραφή στις',
    'Expiring on': 'Λήγει στις',
    'Update Profile': 'Ενημέρωση Προφίλ',
    'Contact Us': 'ΠΛΗΡΟΦΟΡΙΕΣ',
    'Back': 'Επιστροφή',
    'Previous Winners': 'Παλιά νικητές'
}

const Sidebar = {
    'Level': 'Στάδιο',
    'Games': 'Παιχνίδια',
    'Profile': 'Προφίλ',
    'Scores': 'Σκορ',
    'Faq': 'Ερωτήματα',
    'Contact Us': 'Επικοινωνία Μαζί Μας',
    'Back': 'Επιστροφή',
    'Previous Winners': 'Παλιά νικητές'
}

const Alerts = {
    'Welcome to Funiwin': 'Καλώς ήρθατε στο Funiwin',
    'Invalid Mobile Number': 'Μη Έγκυρος Αριθμός Κινητού',
    'Invalid Name': 'Μη Έγκυρο Όνομα',
    'Invalid Email': 'Μη Έγκυρο Email',
    'User Updated': 'Έγινε Ενημέρωση Χρήστη',
    'Image Uploaded Successfully': 'Η Εικόνα Μεταφορτώθηκε Με Επιτυχία',
    'File Size too large': 'Πολύ Μεγάλο Μέγεθος Αρχείου',
    'Server Error': 'Σφάλμα Διακομιστ',
    'All fields are required': 'Όλα τα πεδία είναι υποχρεωτικά',
    'Subscription not found': 'Δεν βρέθηκε συνδρομή',
    'Thank you. Your Feedback has been recorded': 'Ευχαριστώ. Τα σχόλιά σας καταγράφηκαν',
    'Error in adding feedback': 'Σφάλμα κατά την προσθήκη σχολίων',
    'Unauthorized User': 'Μη εξουσιοδοτημένος χρήστης',
}

const Scores = {
    'My scores': 'Οι βαθμολογίες μου',
    'Your Score': 'Σκορ',
    'Retry': 'ξαναπαίζω',
    'No Scores Available': 'Δεν Υπάρχουν Διαθέσιμες Βαθμολογίες',
    'You have not played yet...': 'Δεν έχετε παίξει ακόμη...',
    'Leaderboard': 'Βαθμοί',
    'Your Rank': 'Τάξη',
}

const Game = {
    'Best Score': 'Νίκη',
    'Attempts': 'δοκιμές',
    'Left': 'αριστερά',
    'Score': 'Σκορ',
    'No Bonus': 'Κανένα Μπόνους',
    'You\'ve reached level ': 'Φτάσατε στη σκηνή ',
    'Game Over!': 'Λήξη Παιχνιδιού!',
    'Bonus Score': 'Δώρο σκορ',
    'Your Total Score': 'Συνολικό σκορ',
    'Congratulations!': 'Συγχαρητήρια!',
    'You are now eligible for': 'Είστε πλέον επιλέξιμοι για',
    'We will inform you if you win.': 'Θα σας ενημερώσουμε εάν κερδίσετε.',
    'Sorry!': 'Λυπούμαστε!',
    'You are not eligible for': 'Δεν είστε επιλέξιμοι για',
    'Better luck next time.': 'Καλή επιτυχία την επόμενη φορά.'
}


const HowToPlay = {
    'How to play': 'Πώς να παίξετε',
    'The game will have': 'Το παιχνίδι θα έχει',
    'levels consisting of': 'στάδια που αποτελούνται από',
    'questions in each level.': 'ερωτήσεις σε κάθε στάδιο.',
    'Each question is worth 100 points.': 'Κάθε ερώτηση αξίζει 100 πόντους.',
    'For each incorrect answer there will be a deduction of 10 points from your score.': 'Για κάθε λανθασμένη απάντηση θα αφαιρούνται 10 πόντοι από το σκορ σας.',
    'A timer will be running while you are playing the game. If you complete all the questions within the specified time you earn bonus points.': 'Ένα χρονόμετρο θα τρέχει ενώ παίζετε το παιχνίδι. Εάν ολοκληρώσετε όλες τις ερωτήσεις εντός του καθορισμένου χρόνου κερδίζετε μπόνους πόντους.',
    'A maximum of 5 incorrect attempts are allowed.': 'Επιτρέπονται έως 5 εσφαλμένες προσπάθειες.',
    'Do not refresh the browser tab while playing the games so that your score does not get lost and not recorded.': 'Μην ανανεώνετε την καρτέλα του προγράμματος περιήγησης ενώ παίζετε τα παιχνίδια, ώστε το σκορ σας να μην χαθεί και να καταγραφεί.',
    'Funiwin team wishes you a fun experience and hopes you win your favourite prize!': 'Η ομάδα Funiwin σας εύχεται μια διασκεδαστική εμπειρία και ελπίζει να κερδίσετε το αγαπημένο σας έπαθλο!'
}

const ContactUs = {
    'Contact Us': 'ΠΛΗΡΟΦΟΡΙΕΣ',
    'Submit Feedback': 'Υποβολή Σχολίων',
    'Privacy Policy': 'Πολιτική Απορρήτου',
    'Terms and Conditions': 'Όροι και Προϋποθέσεις',
    'Unsubscribe': 'Eπικοινωνία',
    'Thank you for subscribing to Funiwin.': 'Σας ευχαριστούμε για την εγγραφή σας στο Funiwin.',
    'We would be grateful if you could spare few minutes to provide us a feedback.': 'Θα ήμασταν ευγνώμονες αν μπορούσατε να αφιερώσετε λίγα λεπτά για να μας παρέχετε τα σχόλιά σας.',
    'Content': 'Περιεχόμενο',
    'Easy to Navigate': 'Εύκολη Πλοήγηση',
    'Site Reliability': 'Αξιοπιστία Ιστότοπου',
    'Your Comments': 'Τα Σχόλια Σας'
}

const Faq = {
    'Frequently Asked Questions': 'Ερωτήματα',
    'q1': 'Πώς λειτουργούν τα παιχνίδια;',
    'q2': 'Πόσες φορές μπορώ να παίξω ένα παιχνίδι;',
    'q3': 'Πώς κερδίζω τα έπαθλα;',
    'q4': 'Εάν κερδίσω ένα έπαθλο, πώς μπορώ να το λάβω;',
    'q5': 'Πώς μπορώ να ακυρώσω τη συνδρομή μου;',
    'q6': 'Πώς έχω εγγραφεί στο Funiwin;',
    'q7': 'Υπάρχει χρέωση για χρήση του Funiwin;',
    'q8': 'Πώς μπορώ να επικοινωνήσω με την Εξυπηρέτηση Πελατών;',
    'a10': '\u2022 Κάντε κλικ στο κουμπί "Παίξτε για να κερδίσετε" κάτω από το έπαθλο το καλύτερο σκορ του οποίου θέλετε να ξεπεράσετε για να κερδίσετε.',
    'a11': '\u2022 Απαντήστε στις ερωτήσεις κάνοντας κλικ στις σωστές απαντήσεις.',
    'a12': '\u2022 Μόλις ολοκληρώσετε όλες τις ερωτήσεις, θα δείτε τη βαθμολογία σας.',
    'a2': 'Μπορείτε να παίξετε κάθε παιχνίδι όσες φορές θέλετε! Μέχρι να λήξει το τουρνουά, έχετε τη δυνατότητα να κερδίσετε εάν το σκορ σας είναι το υψηλότερο!',
    'a3': 'Ο παίκτης με το υψηλότερο σκορ κερδίζει το τουρνουά! Τα σκορ παρακολουθούνται έως ότου ολοκληρωθεί ο διαγωνισμός, στο τέλος του διαγωνισμού ο παίκτης με το καλύτερο σκορ σε αυτόν τον διαγωνισμό κερδίζει το έπαθλο.',
    'a4': 'Εάν είστε ο νικητής ενός διαγωνισμού, θα επικοινωνήσουμε μαζί σας στον αριθμό του κινητού σας.',
    'a5': 'Για να ακυρώσετε τη συνδρομή σας, μεταβείτε στην ενότητα "Επικοινωνήστε μαζί μας" στο μενού και καταργήστε την εγγραφή σας από εκεί. Παρακαλούμε αναφέρετε τον λόγο εάν έχετε σκοπό να καταργήσετε την εγγραφή σας, με αυτόν τον τρόπο μάς βοηθάτε να συνεχίσουμε να βελτιώνουμε την υπηρεσία μας για να ικανοποιήσουμε τους πελάτες μας, σας ευχαριστούμε για τον χρόνο σας.',
    'a6': 'Επισκεπτόμενοι μια από τις προωθητικές σελίδες μας και κάνοντας κλικ στο κουμπί εγγραφής. Επιβεβαιώνουμε την εγγραφή σας στις υπηρεσίες μας στέλνοντάς σας ένα μήνυμα SMS στο κινητό σας τηλέφωνο.',
    'a7': 'Χρεώνεστε με την τιμή που αναφέρεται στη σελίδα προσφοράς που επισκεφτήκατε κατά την εγγραφή σας στην υπηρεσία. Μπορείτε επίσης να την βρείτε στο μήνυμα καλωσορίσματος που λάβατε κατά την εγγραφή σας και στη μηνιαία υπενθύμιση SMS που λάβατε από εμάς.',
    'a8': 'Ο αποκλειστικός εικονικός βοηθός μας Εξυπηρέτησης Πελατών θα χαρεί να απαντήσει σε όλες τις ερωτήσεις σας και να λύσει τυχόν προβλήματα. Μπορείτε να κάνετε κλικ στο εικονίδιο της εξυπηρέτησης πελατών στην αρχική σελίδα, θα σας ανακατευθύνει στον επαγγελματικό λογαριασμό WhatsApp και εκεί θα βρείτε απαντήσεις όλο το εικοσιτετράωρο για τις ερωτήσεις σας, τον έλεγχο κατάστασης της υπηρεσίας και για την παροχή σχολίων.',
}

const CookieBanner = {
    'By using any of our Services, you consent to our collection and use of your information in accordance with our Privacy Policy. For more info,': 'Χρησιμοποιώντας οποιαδήποτε από τις Υπηρεσίες μας, συναινείτε στη συλλογή και χρήση των πληροφοριών σας σύμφωνα με την Πολιτική Απορρήτου μας. Για περισσότερες πληροφορίες,',
    'click here': 'πατήστε εδώ',
    'Accept': 'Αποδοχή'
}

export const messages_gr = {...Homepage, ...SplashScreen, ...LoginAccountPage, ...Profile, ...Sidebar, ...Alerts, ...Scores, ...Game, ...HowToPlay, ...ContactUs, ...Faq, ...CookieBanner}
