import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'

export const getFeedback = (uid) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/feedback?uid=${uid}`)
        .then((res) => {
            if(res.data){
                dispatch(getFeedbackInfo(res.data))
            }
        })
        .catch((e) => {
            console.log(e)
        })
    }
}

export const getFeedbackInfo = (data) => {
    return{
        type: actiontypes.GET_FEEDBACK,
        payload: data
    }
}