import React from 'react'
import Logo from './../../assets/images/funiwinlogo.svg'
import {config} from './../../utils/domain_config'
import {VERIFY_MSISDN_URL} from './../../utils/constants'
import {connect} from 'react-redux'
import {authMsisdn} from './../../redux/actions/action_creators/auth_actions'
import {getScores} from './../../redux/actions/action_creators/score_actions'
import {verifyUser} from './../../redux/actions/action_creators/account_actions'
import {show_alerts} from './../../redux/actions/action_creators/alert_actions'
import Alert from '../../components/Alert/Alert';
import Button from './../../components/Button/Button'
import './Login.css'
import Input from '../../components/Input/Input'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { sendData } from '../../analytics/senddata'

class Login extends React.Component{
    
    state = {
        countryCode: '',
        msisdn: '',
        validate: -1,
        load: false
    }

    onKeyDown = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            e.stopPropagation();
            this.onSubmit();
        }
    }

    componentDidMount(){
        window.scrollTo(0,0)
        if(config.countryCode.length === 0) this.setState({countryCode: '0'})
        else this.setState({countryCode: config.countryCode})
    }

    componentDidUpdate(){
        if (sessionStorage.getItem("accountexist") === 'true') {
            this.props.history.push("/home");
        }
    }

    onChange = (e) => {
        let msisdn = e.target.validity.valid ? e.target.value : this.state.msisdn
        this.setState({msisdn: msisdn, validate: -1})
    }

    onSubmit = () => {
            let cc = this.state.countryCode
            axios.post(`${VERIFY_MSISDN_URL}?msisdn=${cc + this.state.msisdn}&domain=${window.location.hostname.includes('localhost') ? 'gr.funiwin.com' : window.location.hostname}`)
            .then((res) => {
                if(res.data.state === "success"){
                    this.setState({validate: 1})
                    this.props.alert({type: 'success', msg: 'Welcome to Funiwin', position: 'top'})
                    let uid = res.data.payload.token
                    sessionStorage.setItem('uid', uid)
                    sessionStorage.setItem('rockmanId', res.data.payload.rockmanId)
                    sendData('', '', '', 'Accessed', { method: 'msisdn', status: 'success'});
                    this.props.authenticateMsisdn(res.data);
                    this.props.verifyUser(uid);
                    this.props.getScores(uid, config.subDomain)
                    this.setState({load: true})
                    setTimeout(() => {
                        if(this.props.isLoggedIn === true && this.props.doesAccountExist === true) {
                            this.props.history.push("/home")
                        }
                        else if(this.props.isLoggedIn === true){
                            this.props.history.push("/account")
                        }
                        else this.props.history.push("/home")
                    }, 2000)
                }
                else if(res.data.state === "failure"){
                    this.setState({validate: 0})
                    this.props.alert({type: 'error', msg: res.data.payload.reason, position: 'top'})

                }
                else{
                    this.setState({validate: 0})
                    this.props.alert({type: 'error', msg: 'Server Error', position: 'top'})
                }
            })
            .catch((e) => {
                console.log(e)
                this.setState({validate: 0})
                this.props.alert({type: 'error', msg: 'Server Error', position: 'top'})
            })
    }
    
    render(){
        return(
            <div className="login_page">
                <Alert />
                <div className="login_page_main">    
                    <div className="p-4 mb-3">
                        <img className="login_logo" src={Logo} alt="logo" />
                    </div>

                    <div className="p-2 text-content mb-3">
                        <div><FormattedMessage id="Welcome" defaultMessage="Welcome" /></div>
                        <div><FormattedMessage id="To login, please enter your mobile number" defaultMessage="To login, please enter your mobile number" /></div>
                    </div>
                </div>
                
                <div className="mt-1 mb-3" style={{padding: '0 8%'}}>
                    <Input onKeyDown={this.onKeyDown} validate={this.state.validate} name="msisdn" type='tel' placeholder={'0000000000'} pattern="[0-9]*" icon="fa fa-mobile" onChange={this.onChange} value={this.state.msisdn} />
                </div>
                
                <div style={{padding: '0 8%'}}>
                    <Button text='LOGIN' onClick={this.onSubmit} />
                    {this.state.load ? <div className="loading_spinner">                
                        <span>
                            <img src={require('./../../assets/images/ajax-loading.gif')} alt="" />
                        </span>
                    </div> : null}                
                </div>
            
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        user_data: state.auth.user_data,
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
        uid: state.auth.uid
    }
}

const mapDispatchToProps = dispatch => {
    return{
        authenticateMsisdn: (msisdn) => dispatch(authMsisdn(msisdn)),
        verifyUser: (uid) => dispatch(verifyUser(uid)),
        getScores: (uid,domain) => dispatch(getScores(uid,domain)),
        alert: (data) => dispatch(show_alerts(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);