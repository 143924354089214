import React from 'react'
import './Timer.css'
import { FormattedMessage } from 'react-intl';

class Timer extends React.Component{
    state = {
        time: this.props.mins * 60
    }

    componentDidMount(){
        this.startTimer = setInterval(() => {
            this.setState((prevState) => ({time: prevState.time - 1}))
        }, 1000);
    }

    componentWillUnmount(){
        clearInterval(this.startTimer)        
    }

    componentDidUpdate(){
        if(this.state.time === 0){
            clearInterval(this.startTimer)
        }
    }

    render(){
        let time = this.state.time;
        let mins = Math.floor(time / 60).toString();
        let secs = Math.floor(time - (mins*60)).toString();
        if(time <= 0){
            return(
                <div className="timer">
                    <div className="warning-timer" style={{borderRadius: '2px'}}>
                        <FormattedMessage id="No Bonus" defaultMessage="No Bonus" />
                    </div>
                </div>
            )
        }
        if(mins < 10) mins = '0' + mins;
        if(secs < 10) secs = '0' + secs;
        return(
        <div className="timer">
            <div className={time < 30 ? 'warning-timer' : 'success-timer'}>{mins}</div>
            <div className={time < 30 ? 'warning-timer' : 'success-timer'}>:</div>
            <div className={time < 30 ? 'warning-timer' : 'success-timer'}>{secs}</div>
        </div>
        )
    }
}

export default Timer;