import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./Header.css";
// import { FormattedMessage } from "react-intl";

class Header extends Component {
  
    state={
        isScrollPos:0
    }
    componentDidMount() {
        window.scrollTo(0,0)
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll=(event) =>{
        let scrollTop = window.pageYOffset
            this.setState({
                isScrollPos:scrollTop
            })
    }
    render(){
        const {isScrollPos}=this.state;
        const props=this.props;
        return(
            <>
                <header className="toolbar" style={props.style}>
                    <nav className="toolbar-navigation">
                        <div>
                            <button className="toolbar-toggle-button" onClick={props.toggleSidebar}>
                                <div className="toolbar-toogle-button-line"></div>
                                <div className="toolbar-toogle-button-line"></div>
                                <div className="toolbar-toogle-button-line"></div>
                            </button>
                        </div>
                        <div className="toolbar-logo">
                            <img src={require('./../../assets/images/funiwinlogo.svg')} width="100" height="40" alt="..." onClick={props.onClick} id="home" />
                        </div>
                        <div className="toolbar-space" />
                        {/* { ( window.location.pathname==='/games' || window.location.pathname==='/leaderboard' )?<Link to='/home'><div className="back-button">
                            <FormattedMessage id="Back" defaultMessage="Back" />    
                        </div></Link>: */}
                        <div className="toolbar-navigation-items">
                        <ul>
                            <li onClick={props.onClick} id="scores" style={{cursor: 'pointer'}}>
                                <i className="fas fa-star header-icon"></i> 
                            </li>
                            <li onClick={props.onClick} id="profile" style={{cursor: 'pointer'}}>
                                <i className="fas fa-user header-icon"></i> 
                            </li>              
                             </ul>
                        </div>
                        {/* } */}
                    </nav>
                </header>
                {isScrollPos<25 && ['/games','/home','/scores', '/leaderboard'].includes(window.location.pathname) &&<div className="rounded-header-bottom">
                </div>}
            </>
        )
    }
}

export default Header;
