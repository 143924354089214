import React from 'react'
import './GameStartScreen.css'
import CombinedHeaderandSidebar from '../CombinedHeaderandSidebar/CombinedHeaderandSidebar'
import howtoplay from './HowToPlay'
import selectgame from './../../assets/images/home/selectgame.svg'
import {isMobile} from './../../utils/helper';
import { FormattedMessage } from 'react-intl'
class GameStartScreen extends React.Component{
    
    onGameStart = () => {
        this.props.startGame();
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }

    render(){
        return(
        <div className="main-container">
            <CombinedHeaderandSidebar />
            <div className="play_content">
                <div className={`${(isMobile.iOS())?'how-to-play-content how-to-play-content-ios':'how-to-play-content'}`}>
                    <div className={`${(isMobile.iOS())?'game-start-header game-start-header-ios':'game-start-header'}`}>
                        <img  className="selected" src={selectgame} alt="1"/>
                         <FormattedMessage id="How to play" defaultMessage="How to play" />{" "}?
                    </div>
                    <div className="Instruction_list">
                        <div className="game_level">
                            <span className="game_level_id">1</span>
                        </div>
                        <div className="game_instruction">
                            <span><FormattedMessage id="The game will have" defaultMessage="The game will have" />{" "}{this.props.level}{" "}<FormattedMessage id="levels consisting of" defaultMessage="levels consisting of" />{" "}{this.props.qperlevel}{" "}<FormattedMessage id="questions in each level." defaultMessage="questions in each level." /></span>
                        </div>
                    </div>
                    {howtoplay.map((item, idx) => {
                        return(
                        <div key={idx} className="Instruction_list" style={idx === 5 ? {marginBottom: '0px'} : null}>
                            <div className="game_level">
                                <span className="game_level_id">{idx+2}</span>    
                            </div>
                            <div className="game_instruction">
                                <span className="instruction">
                                <FormattedMessage id={item} defaultMessage={item} />
                            </span></div>
                        </div>
                        )
                    })}
                </div>
            </div>
            <div className="bottom-ply-button">
            <div className="how-to-play-buttons" onClick={() => this.onGameStart()}>
                    <span className="play-now-button">                        
                    <FormattedMessage id="Start Now" defaultMessage="Start Now" />
                    </span>
                    <span className="play-now-icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
            </div>
            </div>
        </div>
        )
    } 
}

export default GameStartScreen;