import React from 'react'
import './Counter.css'
import {FormattedMessage} from 'react-intl'

class Counter extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            time: new Date(this.props.expiryDate / 1000)
        }
    }

    update = () => {
        var newTime = this.state.time - 1;
        this.setState({time: newTime});
    }

    componentDidMount(){
        this.decr = setInterval(this.update, 1000)
    }

    componentWillUnmount(){
        clearInterval(this.decr)
    }

    render(){
        let className = 'react-countdown-container';
        //let className= 'react-countdown-conatiner-slider-banner';
        let formattedGivenDate = new Date(this.props.expiryDate);
        let today = new Date();
        let msDiff =formattedGivenDate - today;
        let days = parseInt((msDiff/(24*3600*1000)), 10);
        let hours =parseInt((msDiff/(3600*1000)-(days*24)), 10);
        let mins = parseInt((msDiff/(60*1000)-(days*24*60)-(hours*60)), 10);
        let secs = parseInt((msDiff/(1000)-(mins*60)-(days*24*60*60)-(hours*60*60)), 10);
        /* for-slider-banner-counter*/
        if(days === 0 && hours === 0 && mins === 0 && secs === 0){
            clearInterval(this.decr)
            this.props.whenExpired()
            return(
                <div className="expired-time">
                    <FormattedMessage id="Expired" defaultMessage="Expired" />
                </div>
            )
        }
        if(days === 0 && hours <= 2){
            className = 'react-countdown-container danger-time'
        }
        if(days < 10) days = '0' + days;
        if(hours < 10) hours = '0' + hours;
        if(mins < 10) mins = '0' + mins;
        if(secs < 10) secs = '0' + secs;
        // let finalString = `${days}:${hours}:${mins}:${secs}`
        /*style1 is for slider banner time*/
        if(this.props.styletype==="style1"){
            return(
                <div className="react-countdown-conatiner-slider-banner">
                    <div className="ractangle">
                        <FormattedMessage id="Timer" defaultMessage="Timer" />
                    </div>
                    <li style={{borderRightColor: 'lightgray'}}><span>{days}</span>
                        <FormattedMessage id="Days" defaultMessage="Days" />
                    </li>
                    <li style={{borderRightColor: 'lightgray'}}><span>{hours}</span>
                        <FormattedMessage id="Hours" defaultMessage="Hours" />
                    </li>
                    <li style={{borderRightColor: 'lightgray'}}><span>{mins}</span>
                        <FormattedMessage id="Minutes" defaultMessage="Minutes" />
                    </li>
                    <li className="last-li"><span>{secs}</span>
                        <FormattedMessage id="Seconds" defaultMessage="Seconds" />
                    </li>

                </div>
            )
        }
        return(
            <div className={className}>
                <li style={{borderRightColor: 'lightgray'}}><span>{days}</span>
                    <FormattedMessage id="Days" defaultMessage="Days" />
                </li>
                <span className="colon">:</span>
                <li style={{borderRightColor: 'lightgray'}}><span>{hours}</span>
                    <FormattedMessage id="Hours" defaultMessage="Hours" />
                </li>
                <span className="colon">:</span>
                <li style={{borderRightColor: 'lightgray'}}><span>{mins}</span>
                    <FormattedMessage id="Minutes" defaultMessage="Minutes" />
                </li>
                <span className="colon">:</span>
                <li><span>{secs}</span>
                    <FormattedMessage id="Seconds" defaultMessage="Seconds" />
                </li>
            </div>
        )
    }
}

export default Counter;