import React from 'react'
import './Play_button.css'
import {FormattedMessage} from 'react-intl'

const PlayButton = (props) => {
    return(
        <div className="custom-play-button">
           <span className="play">
               <FormattedMessage id="Play to Win" defaultMessage="Play to Win" />
           </span>
            <span className="ArrowIcon">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
            
        </div>
    )
}

export default PlayButton;