export const ShuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const getRandomElements = (a, n) => {
    let arr = ShuffleArray(a);
    return arr.slice(0, n)
}

export const createLevelArray = (level, qperlevel) => {
    var arr = []
    for(let i=0; i<level; i++){
        let temp = new Array(qperlevel).fill(i+1)
        arr = arr.concat(temp)
    }
    return arr;
}

export const nth = (n) => {
    let suffix = ["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
    return `${n}${suffix}`
}

export const imageSelect = (image) => {
    if(image === null) return null;
    if(image.indexOf('https://') !== -1 || image.indexOf('http://') !== -1){
        return image
    }
    else{
        return `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${image}`
    }
}

export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};