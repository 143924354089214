import React from 'react'
import './Button.css'
import { FormattedMessage } from 'react-intl'

const Button = (props) => {
    return(
        <div className="custom-button">
            <button onClick={props.onClick}>
                <FormattedMessage id={props.text} defaultMessage={props.text} />
            </button>
            <span>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
        </div>
    )
}

export default Button;