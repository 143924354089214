export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_SUCCESS_MSISDN = 'AUTH_SUCCESS_MSISDN'

export const AUTH_FAIL = 'AUTH_FAIL'

export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'

export const GET_CONTESTS = 'GET_CONTESTS'
export const GET_TOP_10_SCORES = 'GET_SCORES'
export const GET_TOP_10_LEADERBOARD = 'GET_TOP_10'
export const GET_RANK = 'GET_RANK'
export const RESET_RANK = 'RESET_RANK'

export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const GET_FEEDBACK = 'GET_FEEDBACK'