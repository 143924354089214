const Homepage = {
    'Choose a prize': 'Choose a prize',
    'Play and beat the best score': 'Play & beat the best score',
    'Win the prize!': 'Win the prize!',
    'Play Now': 'Play Now',
    'Play to Win': 'Play to Win',
    'Score to Beat': 'Score to Beat',
    'Days': 'Days',
    'Hours': 'Hours',
    'Minutes': 'Minutes',
    'Seconds': 'Seconds',
    'pts': 'pts',
    'Hurry!': 'Hurry!',
    'Win it now!': 'Win it now!',
    'Loading...': 'Loading...',
    'My contests': 'My contests',
    'Timer': 'Timer'
}

const SplashScreen = {
    'Next': 'Next',
    'Get Started': 'Get Started',
    'Play': 'Play',
    'Play a game and beat the best score': 'Play a game & beat the best score',
    'From a variety of items you may like': 'From a variety of items you may like',
    'Win your prize!': 'Win your prize!',
    'Once the tournament is over, winners are announced': 'Once the tournament is over, winners are announced',
}


const LoginAccountPage = {
    'Welcome': 'Welcome',
    'To login, please enter your mobile number': 'To login, please enter your mobile number',
    'To continue, please signup using your name and email': 'To continue, please signup using your name and email',
    'LOGIN': 'LOGIN',
    'SIGN UP': 'SIGN UP',
}

const Profile = {
    'My profile': 'My profile',
    'Your Name': 'Your Name',
    'Your Email': 'Your Email',
    'Your Subscription Details': 'Your Subscription Details',
    'Mobile Number': 'Mobile Number',
    'Subscribed on': 'Subscribed on',
    'Expiring on': 'Expiring on',
    'Update Profile': 'Update Profile',
    'Back': 'Back',
    'Previous Winners': 'Previous Winners'
}

const Sidebar = {
    'Level': 'Level',
    'Games': 'Games',
    'Profile': 'Profile',
    'Scores': 'Scores',
    'Faq': 'FAQ',
    'Contact Us': 'Contact Us',
    'Back': 'Back'
}

const Alerts = {
    'Welcome to Funiwin': 'Welcome to Funiwin',
    'Invalid Mobile Number': 'Invalid Mobile Number',
    'Invalid Name': 'Invalid Name',
    'Invalid Email': 'Invalid Email',
    'User Updated': 'User Updated',
    'Image Uploaded Successfully': 'Image Uploaded Successfully',
    'File Size too large': 'File Size too large',
    'Server Error': 'Server Error',
    'All fields are required': 'All fields are required',
    'Subscription not found': 'Subscription not found',
}

const Scores = {
    'My scores': 'My scores',
    'Your Score': 'Your Score',
    'Retry': 'Retry',
    'No Scores Available': 'No Scores Available',
    'You have not played yet...': 'You have not played yet...',
    'Leaderboard': 'Leaderboard',
    'Your Rank': 'Your Rank',
}

const Game = {
    'Best Score': 'Best Score',
    'Attempts': 'Attempts',
    'Left': 'Left',
    'Score': 'Score',
    'No Bonus': 'No Bonus',
    'You\'ve reached level ': 'You\'ve reached level ',
    'Game Over!': 'Game Over!',
    'Bonus Score': 'Bonus Score',
    'Your Total Score': 'Your Total Score',
    'Congratulations!': 'Congratulations!',
    'You are now eligible for': 'You are now eligible for',
    'We will inform you if you win.': 'We will inform you if you win.',
    'Sorry!': 'Sorry!',
    'You are not eligible for': 'You are not eligible for',
    'Better luck next time.': 'Better luck next time.'
}

const HowToPlay = {
    'How to play': 'How to play',
    'The game will have': 'The game will have',
    'levels consisting of': 'levels consisting of',
    'questions in each level.': 'questions in each level.',
    'Each question is worth 100 points.': 'Each question is worth 100 points.',
    'For each incorrect answer there will be a deduction of 10 points from your score.': 'For each incorrect answer there will be a deduction of 10 points from your score.',
    'A timer will be running while you are playing the game. If you complete all the questions within the specified time you earn bonus points.': 'A timer will be running while you are playing the game. If you complete all the questions within the specified time you earn bonus points.',
    'A maximum of 5 incorrect attempts are allowed.': 'A maximum of 5 incorrect attempts are allowed.',
    'Do not refresh the browser tab while playing the games so that your score does not get lost and not recorded.': 'Do not refresh the browser tab while playing the games so that your score does not get lost and not recorded.',
    'Funiwin team wishes you a fun experience and hopes you win your favourite prize!': 'Funiwin team wishes you a fun experience and hopes you win your favourite prize!'
}

const ContactUs = {
    'Contact Us': 'Contact Us',
    'Submit Feedback': 'Submit Feedback',
    'Privacy Policy': 'Privacy Policy',
    'Terms and Conditions': 'Terms and Conditions',
    'Unsubscribe': 'Unsubscribe',
    'Thank you for subscribing to Funiwin.': 'Thank you for subscribing to Funiwin.',
    'We would be grateful if you could spare few minutes to provide us a feedback.': 'We would be grateful if you could spare few minutes to provide us a feedback.',
    'Content': 'Content',
    'Easy to Navigate': 'Easy to Navigate',
    'Site Reliability': 'Site Reliability',
    'Your Comments': 'Your Comments'
}

const Faq = {
    'Frequently Asked Questions': 'Frequently Asked Questions',
    'q1': 'How do the games work?',
    'q2': 'How many times can I play a game?',
    'q3': 'How to win the prizes?',
    'q4': 'If I win a prize, how do I receive it?',
    'q5': 'How do I cancel my subscription?',
    'q6': 'How did I get subscribed to Funiwin?',
    'q7': 'Is there a charge for using Funiwin?',
    'q8': 'How do I reach out to Customer Care?',
    'a10': '\u2022 Click the button “Play to win” below the prize you want to beat the best score to win',
    'a11': '\u2022 Answer the questions by clicking on the correct answers.',
    'a12': '\u2022 Once you finish all the questions you will see your score.',
    'a2': 'You can play each game as many times as you wish! Until the tournament expires, you have the ability to win with your score if it is the best!',
    'a3': 'The best score player wins the tournament! Scores are tracked until the competition runs out, at the end of the competition, the player with the best score for that competition wins the prize.',
    'a4': 'If you are the winner of a competition, we will contact you on your mobile number.',
    'a5': 'To cancel your subscription, please go to Contact us in the menu, and unsubscribe from there. Please mention the reason if you intend to unsubscribe, this way it will help us to continue to improve our service to satisfy our customers, we thank you for your time.',
    'a6': 'By coming across one of our promotional pages and clicking on the subscribe button. We confirm your enrolment to our services by sending you an SMS message to your mobile phone.',
    'a7': 'You are charged the rate that is mentioned on the promotional page that you visited when you subscribed to the service. You can also find it in the welcome message you received upon subscribing and in the monthly SMS reminder you receive from us.',
    'a8': 'Our dedicated Customer Care virtual assistant will be happy to answer all your questions and solve any issues. You may click on the icon of customer care on the home page, it will redirect you to whatsapp business account and there you will be helped 24/7 for questions, service status check, and giving feedback.',
}

const CookieBanner = {
    'By using any of our Services, you consent to our collection and use of your information in accordance with our Privacy Policy. For more info,': 'By using any of our Services, you consent to our collection and use of your information in accordance with our Privacy Policy. For more info,',
    'click here': 'click here',
    'Accept': 'Accept'
}

export const messages_en = {...Homepage, ...SplashScreen, ...LoginAccountPage, ...Profile, ...Sidebar, ...Alerts, ...Scores, ...Game, ...HowToPlay, ...ContactUs, ...Faq, ...CookieBanner}