import React from 'react'
import {privacyPolicyLinks} from './../../utils/links'
import {connect} from 'react-redux'
import CookieConsent from 'react-cookie-consent';
import {config} from './../../utils/domain_config'
import { FormattedMessage, injectIntl } from 'react-intl'
const CookieBar = (props) => {
    if(props.isLoggedIn && sessionStorage.getItem('uid') && sessionStorage.getItem('accountexist')){
        return(
            <CookieConsent
                location='bottom'
                buttonText={props.intl.formatMessage({id: 'Accept', defaultMessage: 'Accept'})}
                cookieName='funiwin'
                expires={365}
                sameSite='strict'
                style={{fontSize: '0.9em'}}
                buttonStyle={{backgroundColor: '#FF8C00', borderRadius: '10px'}}
            >
            <FormattedMessage id="By using any of our Services, you consent to our collection and use of your information in accordance with our Privacy Policy. For more info," defaultMessage="By using any of our Services, you consent to our collection and use of your information in accordance with our Privacy Policy. For more info," />
            {" "} <a style={{textDecoration: 'underline', color: 'white'}} href={privacyPolicyLinks[config.subDomain]}>
                <FormattedMessage id="click here" defaultMessage="click here" />
            </a>
            </CookieConsent>
          )
    }
    else return null;
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
    }
}

export default connect(mapStateToProps, null)(injectIntl(CookieBar));
