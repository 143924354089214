import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'

export const getScores = (uid,domain) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/score?uid=${uid}&domain=${domain}`)
        .then((response) => {
            if(response.data.length > 0){
                dispatch(getScoresInfo(response.data))
            }
        })
        .catch((e) => console.log(e))
    }
}

export const getTop10 = (domain_contest_id) => {
    return dispatch => {
        if(domain_contest_id === -1){
            dispatch(getTop10ScoresInfo([]))
        }
        else{
            axios.get(`${BASE_API_URL}/leaderboard?id=${domain_contest_id}`)
            .then((response) => {
                if(response.data.length > 0){
                    dispatch(getTop10ScoresInfo(response.data))
                }
            })
            .catch((e) => console.log(e))
        }
    }
}

export const getRank = (cid, score) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/leaderboard/rank?cid=${cid}&score=${score}`)
        .then((response) => {
            if(response.data){
                dispatch(getRankInfo(response.data.rank))
            }
        })
        .catch((e) => console.log(e))
    }
}

const getTop10ScoresInfo = (data) => {
    return{
        type: actiontypes.GET_TOP_10_LEADERBOARD,
        payload: data
    }
}

const getScoresInfo = (data) => {
    return{
        type: actiontypes.GET_TOP_10_SCORES,
        payload: data
    }
}

const getRankInfo = (data) => {
    return{
        type: actiontypes.GET_RANK,
        payload: data
    }
}

export const resetRank = () => {
    return{
        type: actiontypes.RESET_RANK
    }
}