import {messages_en} from './en'
import {messages_gr} from './gr'
import {config} from './../utils/domain_config'

let messages = {}
switch(config.lang){
    case 'en':
        messages = messages_en;
        break;
    case 'gr':
        messages = messages_gr;
        break;
    default:
        messages = messages_en;
        break;
}

export default messages;