import React from 'react'
import App from './App'
import {IntlProvider} from 'react-intl'
import {config} from './utils/domain_config'
import messages from './translations'
class Langwrapper extends React.Component{
    render(){
        return(
            <IntlProvider locale={config.lang} defaultLocale="en" messages={messages}>
                <App />
            </IntlProvider>
        )
    }
}

export default Langwrapper;