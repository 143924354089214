import React from 'react'
import Logo from './../../assets/images/funiwinlogo.svg'
import './Account.css'
// import FacebookLogin from 'react-facebook-login';
import {connect} from 'react-redux'
import axios from 'axios'
import {verifyUser} from './../../redux/actions/action_creators/account_actions'
import {show_alerts} from './../../redux/actions/action_creators/alert_actions'
import {BASE_API_URL} from './../../utils/constants'
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button/Button';
import Input from './../../components/Input/Input'
import {FormattedMessage, injectIntl} from 'react-intl'
import { sendData } from '../../analytics/senddata'
class Account extends React.Component{
    state = {
        name: '',
        email: '',
        image: '',
        validate_name: -1,
        validate_email: -1,
        load: false,
        uid: sessionStorage.getItem('uid'),
    }

    onKeyDown = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            e.stopPropagation();
            this.onSubmitHandler();
        }
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            validate_name: -1,
            validate_email: -1
        })
    }

    componentDidMount(){
        window.scrollTo(0,0);
        sendData('','','','Viewed',{page: 'Account Page'});
    }

    onSubmitHandler = () => {
        if(this.state.uid !== null){
            let {name, email} = this.state
            let valid_name = 0, valid_email = 0
            let re_name = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/ //eslint-disable-line
            let re_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            if( re_email.test(String(email).toLowerCase()) === false && email.length > 0){
                this.setState({validate_email: 0})
                this.props.alert({type: 'error', msg: 'Invalid Email', position: 'top'})
            } 
            else {
                this.setState({validate_email: 1})
                valid_email = 1
            }
            if( re_name.test(String(name).toLowerCase()) === false && name.length > 0){
                this.setState({validate_name: 0})
                this.props.alert({type: 'error', msg: 'Invalid Name', position: 'top'})
            }
            else {
                this.setState({validate_name: 1})
                valid_name = 1
            }
            if(valid_name === 1 && valid_email === 1){
                this.setState({load: true})
                axios.post(`${BASE_API_URL}/users/addUser`, this.state)
                .then((res) => {
                    this.props.alert({type: 'success', msg: 'Welcome to Funiwin', position: 'top'})
                    this.props.verifyUser(this.state.uid)
                    sendData('','','','Viewed',{type: 'Account Created'});
                    setTimeout(() => {
                        this.props.history.push('/home')
                    }, 3000)
                })
                .catch((e) => {
                    this.props.alert({type: 'error', msg: 'Server Error', position: 'top'})
                    this.setState({
                        name: '',
                        email: ''
                    })
                })
            }
        }
        else this.props.alert({type: 'error', msg: 'All fields are required', position: 'top'})
    }

    onfacebookHandler = (response) => {
        if (response && response.status !== 'unknown'){
            console.log(response)
            let name = response.name;
            let email = response.email;
            let image = response.picture.data.url
            this.setState({
                name: name,
                email: email,
                image: image
            })
            this.onSubmitHandler();
        }
        else{
            this.props.alert({type: 'error', msg: 'Server Error', position: 'top'})
        }
    }

    componentDidUpdate(){
        if(this.props.isLoggedIn !== true) this.props.history.push("/login")
    }
    
    render(){
        return(
            <div className="account_page">
                <Alert />
                <div className="account_page_main">
                
                    <div className="p-4 mb-2">
                        <img src={Logo} height="63" width="150" alt="logo" />
                    </div>
                
                    <div className="p-2 text-content mb-3">
                        <div>
                        <FormattedMessage id="Welcome" defaultMessage="Welcome" />
                        </div>
                        <div><FormattedMessage id="To continue, please signup using your name and email" defaultMessage="To continue, please signup using your name and email" /> </div>
                    </div>
                
                </div>

                <div className="mt-1 mb-3" style={{padding: '0 2.5%', margin: '0 5%'}}>
                    <Input onKeyDown={this.onKeyDown} validate={this.state.validate_name} type='text' placeholder={this.props.intl.formatMessage({id: 'Your Name', defaultMessage: 'Your Name'})} icon="fa fa-user" onChange={this.onChangeHandler} value={this.state.name} name="name" />
                </div>

                <div className="mt-1 mb-3" style={{padding: '0 2.5%', margin: '0 5%'}}>
                    <Input onKeyDown={this.onKeyDown} validate={this.state.validate_email} type='email' placeholder={this.props.intl.formatMessage({id: 'Your Email', defaultMessage: 'Your Email'})} icon="fa fa-envelope" onChange={this.onChangeHandler} value={this.state.email} name="email" />         
                </div>

                {!this.state.load ? <div className="mt-4" style={{padding: '0px 2.5%',margin: '0px 5%'}}>

                    <Button text='SIGN UP' onClick={this.onSubmitHandler} />
                    <div className="or_divider">OR</div>
                    <Button text='SKIP FOR NOW' onClick={this.onSubmitHandler} />  
                    {/* <div className="or_divider">OR</div>
                    <div className="custom_fb_button_container">
                        <FacebookLogin
                        appId="481453712684332"
                        isMobile={false}
                        redirectUri={window.location.hostname}
                        autoLoad={false}
                        textButton="Connect"
                        fields="name,email,picture.width(300).height(300)"
                        onClick={null}
                        callback={this.onfacebookHandler}
                        cssClass="custom_fb_button" />
                        <span>
                            <i className="fab fa-facebook"></i>
                        </span>
                    </div> */}
                </div>
                :
                <div className="loading_spinner">                
                    <span>
                        <img src={require('./../../assets/images/ajax-loading.gif')} alt="" />
                    </span>
                </div>
                }
            </div>
        )

    }
}

const mapStateToProps = state => {
    return{
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
    }
}


const mapDispatchToProps = dispatch => {
    return{
      verifyUser: (uid) => dispatch(verifyUser(uid)),
      alert: (data) => dispatch(show_alerts(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Account));