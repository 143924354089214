import React, { Component } from "react";
import "./LeaderBoard.css";
import CombinedHeaderandSidebar from "./../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar";
import dp from "./../../assets/images/dp.png";
import {  nth, imageSelect } from "./../../utils/helper";
import { connect } from "react-redux";
import {
  getTop10,
  resetRank,
} from "./../../redux/actions/action_creators/score_actions";
import { FormattedMessage } from "react-intl";
import { sendData } from "../../analytics/senddata";

class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contest_id: null,
      contest_name: "",
      your_score: null,
      loading: false,
      your_rank: null,
      image: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    sendData('','','','Viewed',{page: 'Leaderboard', rank: this.props.rank})
    if (
      this.props.location.state !== null &&
      this.props.contest.length !== 0 &&
      this.props.user_data !== null
    ) {
      let contest_data = this.props.contest.filter((item) => {
        return item.id === parseInt(this.props.location.state.index);
      })[0];
      let score = this.props.location.state.score;
      let image = this.props.user_data.image;
      this.setState({
        contest_id: contest_data.id,
        contest_name: contest_data.prize_name,
        your_score: score,
        image: image,
      });
    }
  }
  retry = (e) => {
    if (e.target.id !== null) {
      this.props.history.push({
        pathname: "/games",
        state: { index: e.target.id },
      });
    }
  };
  componentDidUpdate() {
    if (
      sessionStorage.getItem("accountexist") === null &&
      this.props.isLoggedIn !== true
    ) {
      this.props.history.push("/");
    } else if (this.state.contest_id === null) this.props.history.push("/home");
  }
  componentWillUnmount() {
    this.props.getTop10(-1);
    this.props.resetRank();
  }
  render() {
    return (
      <div className="main-leaderboard-container">
        <CombinedHeaderandSidebar />
        <div className="leaderboard-container">
          <div className="top-10-header">
            <div className="top-10-heading-text">
              <FormattedMessage id="Leaderboard" defaultMessage="Leaderboard" />
            </div>
            <div className="img-content">
            <div className="top-10-header-image">
              <img
                src={
                  imageSelect(this.state.image) === null
                    ? dp
                    : imageSelect(this.state.image)
                }
                alt=""
              />
            </div>
            <div className="top-10-header-content">
              <div className="best-rank-title">
                <span><FormattedMessage id="Your Rank" defaultMessage="Your Rank" />:</span>
                <span>
                  {this.props.rank !== null ? nth(this.props.rank) : 0}
                </span>
              </div>
              <div className="header-top-title">
                <span>{this.state.contest_name}</span>
              </div>
              <div className="best-score-title">
                <span>
                  <FormattedMessage id="Your Score" defaultMessage="Your Score" />:
                </span>
                <span>{this.state.your_score} 
                {" "} <FormattedMessage id="pts" defaultMessage="pts" />
                </span>
              </div>
            </div>
            </div>
          </div>
          <div className="top-10-list-main">
            {this.props.scores !== null && this.props.scores.length > 1
              ? this.props.scores.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="top-10-list"
                      style={
                        index === this.props.scores.length - 1
                          ? { borderBottom: "none" }
                          : null
                      }
                    >
                      <div className="top-10-id">
                        <span>{item.rank}</span>
                      </div>
                      <div className="top-10-list-card">
                        <div className="top-10-car-img">
                          <img
                            src={
                              imageSelect(item.image) === null
                                ? dp
                                : imageSelect(item.image)
                            }
                            alt={item.name}
                          />
                        </div>
                        <div className="top-10-card-name">
                          <span>{item.name.split(" ")[0]}</span>
                          <span><FormattedMessage id="Level" defaultMessage="Level" />{" "}{item.level}</span>
                        </div>
                      </div>
                      <div className="top-10-time">
                          <span>{item.score}{" "}<FormattedMessage id="pts" defaultMessage="pts" /></span>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div
            className="leader-button"
            id={this.state.contest_id}
            onClick={this.retry}
          >
            <span className="rank-button">
            <FormattedMessage id="Retry" defaultMessage="Retry" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_data: state.auth.user_data,
    contest: state.contest.contest_data,
    scores: state.score.top_10_scores,
    rank: state.score.curr_rank,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTop10: (dc_id) => dispatch(getTop10(dc_id)),
    resetRank: () => dispatch(resetRank()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);
