import * as actiontypes from './../actions/actiontypes'
const initState = {
    status: false,
    type: '',
    msg: '',
    position: ''
}
const AlertReducer = (state = initState, action) => {
    switch(action.type){
        case actiontypes.SHOW_ALERT:
            return {...state, status: true, type: action.msgtype, msg: action.msg, position: action.position}
        case actiontypes.HIDE_ALERT:
            return {...state, status: false}
        default:
            return {...state}
    }
}

export default AlertReducer;
