import * as actiontypes from './../actions/actiontypes'
const initState = {
    comments: '',
    content: 0,
    navigate: 0,
    reliable: 0,
}

const FeedbackReducer = (state = initState, action) => {
    switch(action.type){
        case actiontypes.GET_FEEDBACK:
            let {comments, content, navigate, reliable} = action.payload
            if(comments === null || comments === undefined){
                comments = ''
            }
            return {...state, comments: comments, content: content, navigate: navigate, reliable: reliable}
        default:
            return {...state}
    }
}

export default FeedbackReducer;