import * as actiontypes from './../actions/actiontypes'
const initAuthState = {
    isLoggedin: null,
    token: null,
    doesAccountExist: null,
    user_data: null,
    uid: null,
    msisdn: null,
    subscribed_at: null,
    expired_at: null
}

const AuthReducer = (state = initAuthState, action) => {
    switch(action.type){
        case actiontypes.AUTH_START:
            return {...state}
        case actiontypes.AUTH_SUCCESS:
            return {...state, 
                isLoggedin: true, 
                token: action.payload.rockmanId, 
                msisdn: action.payload.msisdn, 
                subscribed_at: action.payload.startDate, 
                expired_at: action.payload.expiryDate, 
                uid: action.payload.uid
            }
        case actiontypes.AUTH_SUCCESS_MSISDN:
            return {...state, 
                isLoggedin: true, 
                token: action.payload.payload.rockmanId, 
                msisdn: action.payload.payload.msisdn, 
                subscribed_at: action.payload.payload.startDate, 
                expired_at: action.payload.payload.expiryDate, 
                uid: action.payload.payload.token
            }
        case actiontypes.AUTH_FAIL:
            return {...state, isLoggedin: false}
        case actiontypes.VERIFY_ACCOUNT:
            return {...state, doesAccountExist: action.verify, user_data: action.data}
        case actiontypes.UPDATE_ACCOUNT:
            return {...state, user_data: action.payload}
        default:
            return state
    }
}

export default AuthReducer;