const domainConfig = {
    'demo': {
        country: 'us',
        lang: 'en',
        countryCode: ''
    },
    'gr': {
        country: 'gr',
        lang: 'gr',
        countryCode: '30'
    },
    'gr-mobimilia': {
        country: 'gr',
        lang: 'gr',
        countryCode: '30'
    },
    'za': {
        country: 'za',
        lang: 'en',
        countryCode: '27'
    }
}
const defaultConfig = {
    country: 'us',
    lang: 'en',
    countryCode: ''
}

export let config = {};

export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return parts[0];
}

const getConfigsForSubdomain = (subdomain) => {
    let configForSubDomain = domainConfig[subdomain] || defaultConfig;
    configForSubDomain.subDomain = subdomain;
    return configForSubDomain;
}

if (getSubdomain() === 'localhost') config = getConfigsForSubdomain('gr');
else config = getConfigsForSubdomain(getSubdomain());
