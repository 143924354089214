import * as actiontypes from './../actions/actiontypes'
const initState = {
    contest_data: []
}

const ContestReducer = (state = initState, action) => {
    switch(action.type){
        case actiontypes.GET_CONTESTS:
            return {...state, contest_data: action.payload}
        default:
            return {...state}
    }
}

export default ContestReducer;