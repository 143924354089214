import React from 'react'
import './Loader.css'
import { FormattedMessage } from 'react-intl'

const Loader = (props) => {
   return(
        <div className="loading-container-main">
            <div className="loading-container-inner">
            <div className="loading-cont-header">
                <FormattedMessage id={`My ${props.name}`} defaultMessage={`My ${props.name}`} />
            </div>
            <div className="loading-screen-custom">
                <div className="loader-text-custom">
                {props.name === 'scores' 
                ? 
                <FormattedMessage id='You have not played yet...' defaultMessage='You have not played yet...' />  
                : 
                <FormattedMessage id='Loading...' defaultMessage='Loading...' />
                }
                </div>
                {props.name !== 'scores'
                ? <i className="fas fa-spinner fa-pulse icon" style={{color: '#F37335', fontSize: '24px'}}></i>
                : null}
            </div>
            </div>
        </div>
    )
}

export default Loader;