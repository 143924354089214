import React from 'react'
import {connect} from 'react-redux'
import './Contact.css'
import CombinedHeaderandSidebar from '../../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar'
import { FormattedMessage, injectIntl } from 'react-intl'
import {unsubLinks, tnCLinks, privacyPolicyLinks} from './../../../utils/links'
import {config} from './../../../utils/domain_config'
import Rating from 'react-ratings-declarative'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'
import {show_alerts} from './../../../redux/actions/action_creators/alert_actions'
import {getFeedback} from './../../../redux/actions/action_creators/feedback_actions'
import { sendData } from '../../../analytics/senddata'

class Contact extends React.Component{
    state = {
        content: 0,
        navigate: 0,
        reliable: 0,
        comments: '',
    }

    componentDidMount(){
        let {comments, reliable, navigate, content} = this.props.feedbackData
        this.setState({
            content, navigate, reliable, comments
        })
    }

    onChangeHandler = (e) => {
        this.setState({
            comments: e.target.value
        })
    }

    onClickHandler = (id) => {
        let uid = sessionStorage.getItem('uid')
        switch(id){
            case 'unsub':
                sendData('','','','Unsubscribed',{type: 'Clicked on Unsubscribe'})
                const unsub_url = new URL(unsubLinks[config.subDomain])
                if(config.subDomain === "za"){
                    unsub_url.searchParams.append("token", uid)
                }
                window.open(unsub_url.href)
                break;
            case 'pp':
                window.open(privacyPolicyLinks[config.subDomain])
                break;
            case 'tnc':
                window.open(tnCLinks[config.subDomain])
                break;
            default:
                window.open(privacyPolicyLinks[config.subDomain])
                break;
        }
    }

    changeRating = (newRating, type) => {
        this.setState({
            [type]: newRating
        })
    }

    onSubmitHandler = () => {
        let uid = sessionStorage.getItem('uid')
        if(uid){
            axios.post(`${BASE_API_URL}/feedback`, {...this.state, uid: uid})
            .then((res) => {
                if(res.status === 200){
                    this.props.alert({type: 'success', msg: 'Thank you. Your Feedback has been recorded'})
                }
                else{
                    this.props.alert({type: 'error', msg: 'Error in adding feedback'})
                }
            })
            .catch((err) => {
                console.log(err)
                this.props.alert({type: 'error', msg: 'Error in adding feedback'})
            })
        }
        this.props.alert({type: 'error', msg: 'Unauthorized User'})
    }

    componentWillUnmount(){
        let uid = sessionStorage.getItem('uid')
        this.props.getFeedback(uid)
    }
    
    render(){
        return(
            <>
            <CombinedHeaderandSidebar />
            <div className="contact-heading-custom">
                <FormattedMessage id="Contact Us" defaultMessage="Contact Us" />
            </div>
            <div className="feedback-main">
                <div className="feedback-title">
                    <span>
                        {this.props.intl.formatMessage({id: 'Thank you for subscribing to Funiwin.', defaultMessage: 'Thank you for subscribing to Funiwin.'})}
                    </span>
                    <span>
                    {this.props.intl.formatMessage({id: 'We would be grateful if you could spare few minutes to provide us a feedback.', defaultMessage: 'We would be grateful if you could spare few minutes to provide us a feedback.'})}
                    </span>
                </div>
                <div className="feedback-star-ratings">
                    <div className="rate-feedback">
                        <span>
                            {this.props.intl.formatMessage({id: 'Content', defaultMessage: 'Content'})}:
                        </span>
                        <span>
                        <Rating 
                            rating={this.state.content}
                            widgetDimensions='18px'
                            widgetSpacings='6px'
                            widgetRatedColors='rgb(255, 165, 0)'
                            widgetHoverColors='rgb(255, 165, 0)'
                            changeRating={(newRating) => {this.changeRating(newRating, 'content')}}
                        >
                            <Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget />
                        </Rating>
                        </span>
                    </div>
                    <div className="rate-feedback">
                        <span>
                            {this.props.intl.formatMessage({id: 'Easy to Navigate', defaultMessage: 'Easy to Navigate'})}:
                        </span>
                        <span>
                        <Rating 
                            rating={this.state.navigate}
                            widgetDimensions='18px'
                            widgetSpacings='6px'
                            widgetRatedColors='rgb(255, 165, 0)'
                            widgetHoverColors='rgb(255, 165, 0)'
                            changeRating={(newRating) => {this.changeRating(newRating, 'navigate')}}
                        >
                            <Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget />
                        </Rating>
                        </span>
                    </div>
                    <div className="rate-feedback">
                        <span>
                          {this.props.intl.formatMessage({id: 'Site Reliability', defaultMessage: 'Site Reliability'})}:                    
                        </span>
                        <span>
                        <Rating 
                            rating={this.state.reliable}
                            widgetDimensions='18px'
                            widgetSpacings='6px'
                            widgetRatedColors='rgb(255, 165, 0)'
                            widgetHoverColors='rgb(255, 165, 0)'
                            changeRating={(newRating) => {this.changeRating(newRating, 'reliable')}}
                        >
                            <Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget /><Rating.Widget />
                        </Rating>
                        </span>
                    </div>
                    <div className="feedback-text-comment">
                        <textarea type="text" placeholder={this.props.intl.formatMessage({id: 'Your Comments', defaultMessage: 'Your Comments'})} value={this.state.comments} onChange={this.onChangeHandler} />
                    </div>
                </div>
                <div className="feedback-button">
                <button onClick={this.onSubmitHandler}>
                    <FormattedMessage id="Submit Feedback" defaultMessage="Submit Feedback" />
                </button>
                </div>
            </div>
            <div className="extra-utils-contact">
                <button onClick={() => {this.onClickHandler('pp')}}>
                <FormattedMessage id="Privacy Policy" defaultMessage="Privacy Policy" />
                </button>
                <button onClick={() => {this.onClickHandler('tnc')}}>
                <FormattedMessage id="Terms and Conditions" defaultMessage="Terms and Conditions" />
                </button>
                <button onClick={() => {this.onClickHandler('unsub')}}>
                    <FormattedMessage id="Unsubscribe" defaultMessage="Unsubscribe" />
                </button>
            </div>
            </>
        )    
    }
}


const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
        feedbackData: state.feedback
    }
}

const mapDispatchToProps = dispatch => {
    return{
        alert: (data) => dispatch(show_alerts(data)),
        getFeedback: (uid) => dispatch(getFeedback(uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Contact));