import * as actiontypes from './../actions/actiontypes'
const initState = {
    all_questions_loaded: false,
    all_questions: [],
}

const GameReducer = (state = initState, action) => {
    switch(action.type){
        case actiontypes.GET_QUESTIONS:
            return {...state, all_questions_loaded: action.loaded, all_questions: action.payload}
        default:
            return {...state}
    }
}

export default GameReducer;