import React from 'react';
import './Profile.css';
import CombinedHeaderandSidebar from '../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar';
import {connect} from 'react-redux';
import dp from './../../assets/images/dp.png';
import Loader from '../../components/Loader/Loader';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import axios from 'axios';
import {updateUser} from './../../redux/actions/action_creators/account_actions'
import {show_alerts} from './../../redux/actions/action_creators/alert_actions'
import {BASE_API_URL} from './../../utils/constants'
import { FormattedMessage,  injectIntl } from 'react-intl';
import { sendData } from '../../analytics/senddata';
import { config } from '../../utils/domain_config';

class Profile extends React.Component{
    state = {
        name: '',
        email: '',
        imageurl: '',
        imagelink: '',
        loaded: false,
        progress: -1,
        imageloaded: false,
        show_sub: false,
        data: {},
        uid: sessionStorage.getItem('uid')
    }

    componentDidUpdate(){
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true){
            this.props.history.push("/")
        }
        if(this.state.loaded === false && this.props.user_data !== null){
            let {name, email, image} = this.props.user_data
            if(image === null){
                this.setState({
                    imagelink: '',
                    imageurl: ''
                })
            }
            else if (image.indexOf('http://') !== -1 || image.indexOf('https://') !== -1){
                this.setState({
                    imagelink: image,
                    imageurl: image
                })
            } 
            else {
                this.setState({
                    imagelink: image,
                    imageurl: `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${image}` 
                })
            } 
            this.setState({
                name: name,
                email: email,
                loaded: true,
                data: this.props.user_data
            })
        }
    }

    onKeyDown = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            e.stopPropagation();
            this.onSubmitHandler();
        }
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    viewUserSub = (e) => {
        this.setState((prevState) => ({
            show_sub: !prevState.show_sub
        }))
    }

    onSubmitHandler = (e) => {
        let data = {...this.state.data, name: this.state.name, email: this.state.email, image: this.state.imagelink.length > 0 ? this.state.imagelink : null}
        let {name, email, uid, imagelink} = this.state
        if(uid.length > 0){
            let re_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            if(re_email.test(String(email).toLowerCase()) === false && email.length > 0){
                this.props.alert({type: 'error', msg: 'Invalid Email'})
            } 
            else{
                let obj = {
                    name: name,
                    email: email,
                    uid: uid,
                    imagelink: imagelink
                }
                axios.post(`${BASE_API_URL}/users/updateUser`, obj)
                .then((res) => {
                   if(res.status === 200){
                    this.props.alert({type: 'success', msg: 'User Updated'})
                    this.props.updateUser(data);
                   } 
                })
                .catch((e) => {
                    this.props.alert({type: 'error', msg: 'Server Error'})
                })
            }
        }
        else this.props.alert({type: 'error', msg: 'You are not authorized'})
    }

    onFileChange = (e) => {
        let reader = new FileReader()
        let file = e.target.files[0]
        if(file){
            if(file && file.size >= 1500000) this.props.alert({type: 'error', msg: 'File Size too large'})
            else{
                reader.readAsDataURL(file)
                reader.onloadend = () => {
                    this.uploadImage(reader.result)
                    this.setState({
                        imageurl: reader.result,
                        imageloaded: true
                    })
                }
            }
        }
    }

    uploadImage = (file) => {
        if(file){
            let base64Data = file;
            axios.post(`${BASE_API_URL}/users/upload`, {data: base64Data, field_name: 'profile-pictures'}, {
                onUploadProgress: (ProgressEvent) => {
                    let progress = Math.round((ProgressEvent.loaded * 100) / (ProgressEvent.total))
                    this.setState({progress: progress})
                }
            })
            .then((res) => {
                let url = res.data.url
                this.setState({progress: -100, imageurl: url, imageloaded: false})
                this.props.alert({type: 'success', msg: 'Image Uploaded Successfully'})
                url = new URL(url);
                url = url.pathname.split("/").filter((item, index) => index > 1).join("/")
                this.setState({imagelink: url})
            })
            .catch((e) => {
                console.log(e)
                this.props.alert({type: 'error', msg: 'Server Error'})
            })    
        }
        else this.props.alert({type: 'error', msg: 'Server Error'})
    }

    componentDidMount(){
        window.scrollTo(0,0)
        sendData('','','','Viewed',{page: 'Profile Page'})
        if(this.props.user_data !== null){ 
            let {name, email, image} = this.props.user_data
            if(image === null){
                this.setState({
                    imagelink: '',
                    imageurl: ''
                })
            }
            else if (image.indexOf('http://') !== -1 || image.indexOf('https://') !== -1){
                this.setState({
                    imagelink: image,
                    imageurl: image
                })
            } 
            else {
                this.setState({
                    imagelink: image,
                    imageurl: `https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${image}` 
                })
            } 
            this.setState({
                name: name,
                email: email,
                loaded: true,
                data: this.props.user_data
            })
        }
    }

    render(){
        let imagehref = dp
        let blurimage = null
        if(this.state.imageurl.length !== 0) imagehref = this.state.imageurl
        if(this.state.imageurl.length !== 0 && this.state.imageloaded){
            blurimage = {filter: 'blur(2px)'}
        }
        return (
          <>
            <CombinedHeaderandSidebar />
            <div className="profile-heading-custom">
              {this.state.loaded === true ? (
                <FormattedMessage id="My profile" defaultMessage="My Profile" />
              ) : (
                ""
              )}
            </div>
            {this.state.loaded === true ? (
              <>
                <div className="profile-image-custom">
                  <label htmlFor="upload-profile-picture">
                    <img style={blurimage} src={imagehref} alt="Logo" />
                  </label>
                  <input
                    type="file"
                    id="upload-profile-picture"
                    accept="image/jpg, image/png, image/jpeg"
                    hidden
                    onChange={this.onFileChange}
                  />
                  {this.state.progress > 0 ? (
                    <div className="custom-progress">
                      <Progress percent={this.state.progress} />
                    </div>
                  ) : null}
                </div>

                <div style={{ padding: "0 2.5%", margin: "2% 10%" }}>
                  <div className="form-group has-search3">
                    <span className="fa fa-user form-control-feedback3"></span>
                    <input
                      onKeyDown={this.onKeyDown}
                      type="text"
                      className="form-control"
                      placeholder={this.props.intl.formatMessage({
                        id: "Your Name",
                        defaultMessage: "Your Name",
                      })}
                      value={this.state.name}
                      name="name"
                      onChange={this.onChangeHandler}
                    />
                  </div>

                  <div className="form-group has-search3">
                    <span className="fa fa-envelope form-control-feedback3"></span>
                    <input
                      onKeyDown={this.onKeyDown}
                      type="text"
                      className="form-control"
                      placeholder={this.props.intl.formatMessage({
                        id: "Your Email",
                        defaultMessage: "Your Email",
                      })}
                      value={this.state.email}
                      name="email"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  {/* <div className="show-user-details" onClick={this.viewUserSub}>
                    <span><i className="fa fa-user-cog"></i></span>
                    <span><FormattedMessage id="Your Subscription Details" defaultMessage="Your Subscription Details" /></span>
                </div>
                {this.state.show_sub ? <div className="user-details">
                    <div>
                        <span><FormattedMessage id="Mobile Number" defaultMessage="Mobile Number" /> :</span> 
                        <span>{this.props.msisdn}</span>
                    </div>
                    <div>
                        <span><FormattedMessage id="Subscribed on" defaultMessage="Subscribed on" /> :</span> 
                        <span>{this.props.subscribed_at}</span>
                    </div>
                    <div><span><FormattedMessage id="Expiring on" defaultMessage="Expiring on" /> :</span> 
                        <span>{this.props.expired_at}</span>
                    </div>
                </div> : null} */}

                  <button
                    onKeyDown={this.onKeyDown}
                    className="custom-profile-btn-class"
                    style={{ width: "100%", marginTop: "1.2rem" }}
                    onClick={this.onSubmitHandler}
                  >
                    <FormattedMessage
                      id="Update Profile"
                      defaultMessage="Update Profile"
                    />
                  </button>
                  {config.country === "za" && (
                    <p className="unsubText">
                      To unsubscribe, dial <strong>135*997#</strong>
                    </p>
                  )}
                </div>
              </>
            ) : (
              <Loader name="profile" />
            )}
          </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_data: state.auth.user_data,
        isLoggedIn: state.auth.isLoggedin,
        msisdn: state.auth.msisdn,
        subscribed_at: state.auth.subscribed_at,
        expired_at: state.auth.expired_at,
        doesAccountExist: state.auth.doesAccountExist
    }
}

const mapDispatchToProps = dispatch => {
    return{
        updateUser: (data) => dispatch(updateUser(data)),
        alert: (data) => dispatch(show_alerts(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Profile));