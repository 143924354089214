import React from "react";
import { connect } from "react-redux";
import "./Games.css";
import axios from "axios";
import { BASE_API_URL } from "./../../utils/constants";
import { getContests } from "./../../redux/actions/action_creators/contest_actions";
import {
  getScores,
  getTop10,
  getRank
} from "./../../redux/actions/action_creators/score_actions";
import { verifyUser } from "./../../redux/actions/action_creators/account_actions";
import { config } from "./../../utils/domain_config";
import GameStartScreen from "../../components/GameStartScreen/GameStartScreen";
import GameEndScreen from "../../components/GameEndScreen/GameEndScreen";
import CombinedHeaderandSidebar from "../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar";
import GamePlay from "../../components/GamePlay/GamePlay";
import ContestCard from "../../components/ContestCard/ContestCard";
import { createLevelArray } from "./../../utils/helper";
import { FormattedMessage } from "react-intl";
import { sendData } from "../../analytics/senddata";

class Games extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: sessionStorage.getItem("uid"),
      contest_id: null,
      game_id: null,
      contest_name: "",
      contest_img: "",
      maxscore: 0,
      level: null,
      qperlevel: null,
      levelArray: [],
      changing: false,
      start: false,
      curr_index: 0,
      score: 0,
      bonus_score: 0,
      finished: false,
    };
    this.getTimeRemaining = React.createRef();
  }

  startGame = () => {
    let data = { dc_id: this.state.contest_id, uid: this.state.uid };
    axios
      .post(`${BASE_API_URL}/score/gameStart`, data)
      .then((res) => {
        let contest_data = this.props.contest.filter((x) => {
          return x.id === data.dc_id
        })[0]
        sendData(contest_data.prize_name, '','Contest','Played',{type: 'Contest Started'});
        this.setState({ start: true, game_id: res.data.gameid });
      })
      .catch((e) => {
        console.log(e);
        this.props.history.push("/");
      });
  };

  componentDidUpdate() {
    if (
      sessionStorage.getItem("accountexist") === null &&
      this.props.isLoggedIn !== true
    ) {
      this.props.history.push("/login");
    } else if (this.state.contest_id === null) this.props.history.push("/home");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.state !== null && this.props.contest.length !== 0) {
      let contest_data = this.props.contest.filter((item) => {
        return item.id === parseInt(this.props.location.state.index);
      })[0];
      sendData(contest_data.prize_name, '','Contest','Viewed',{page: 'Instruction Page'})
      this.setState({
        contest_id: contest_data.id,
        contest_name: contest_data.prize_name,
        contest_img: contest_data.prize_img,
        maxscore: contest_data.maxscore,
        level: contest_data.no_of_levels,
        qperlevel: contest_data.no_of_questions_per_level,
        levelArray: createLevelArray(
          contest_data.no_of_levels,
          contest_data.no_of_questions_per_level
        ),
      });
    }
  }

  onGameEnd = (score, time_left_score) => {
    let data = {
      score: score + time_left_score,
      gameid: this.state.game_id,
      level: this.state.levelArray[this.state.curr_index],
    };
    let contest_data = this.props.contest.filter((x) => {
      return x.id === this.state.contest_id
    })[0]
    sendData(contest_data.prize_name, '','Contest','Played',{type: 'Contest Completed', score: data.score});
    axios
      .post(`${BASE_API_URL}/score/gameEnd`, data)
      .then((res) => {
        this.setState({
          score: score,
          bonus_score: time_left_score,
          finished: true,
        });
        this.props.getContests(config.subDomain);
        this.props.getScores(this.state.uid, config.subDomain);
        this.props.verifyUser(this.state.uid);
        this.props.getTop10(this.state.contest_id);
        this.props.getRank(this.state.contest_id, this.state.score + this.state.bonus_score)
      })
      .catch((e) => {
        console.log(e);
        this.props.history.push("/");
      });
  };

  changeQuestion = () => {
    this.setState({ changing: true });
    let images = document.getElementsByClassName("tick-image");
    images = [...images];
    images.map((item) => item.classList.remove("ticked"));
    this.setState((prevState) => ({ curr_index: prevState.curr_index + 1 }));
    setTimeout(() => {
      this.setState({ changing: false });
    }, 1000);
  };

  retry = () => {
    if (this.state.score + this.state.bonus_score > this.state.maxscore) {
      let score = this.state.score + this.state.bonus_score;
      if (score < 0) score = 0;
      this.setState({ maxscore: score });
    }
    this.setState({ start: false, finished: false, curr_index: 0 });
  };

  goBack = () => {
    this.props.history.push("/home");
  };

  goToLeaderBoard = () => {
    this.props.history.push({
        pathname: '/leaderboard',
        state: {index: this.state.contest_id, score: this.state.score + this.state.bonus_score}
    })
  };

  componentWillUnmount() {
    this.props.getContests(config.subDomain);
    this.props.getScores(this.state.uid, config.subDomain);
    this.props.verifyUser(this.state.uid);
  }

  render() {
    let { levelArray, curr_index } = this.state;
    if (this.state.start === false && this.state.finished === false) {
      return (
        <GameStartScreen
          startGame={this.startGame}
          level={this.state.level}
          qperlevel={this.state.qperlevel}
          goBack={this.goBack}
        />
      );
    } else if (this.state.start === true && this.state.finished === false)
      return (
        <div className="games-main">
          <CombinedHeaderandSidebar />
          <div className="games-container">
            <ContestCard
            name={this.state.contest_name}
            img={this.state.contest_img}
            score={this.state.maxscore}
          />
          <div className="game-screen">
            <div className="level-indicator">
              <div>
                <FormattedMessage id="Level" defaultMessage="Level" />
              </div>
              <div>{levelArray[curr_index]}</div>
            </div>
            <div className="game-card">
              <div className="gameplay-error-overlay">
                <span>-10</span>
              </div>
              <GamePlay
                index={this.state.curr_index}
                changing={this.state.changing}
                changeQuestion={this.changeQuestion}
                start={this.state.start}
                onGameEnd={this.onGameEnd}
                level={this.state.level}
                qperlevel={this.state.qperlevel}
              />
            </div>
          </div>
            </div>
         
          </div>
      );
    else
      return (
        <GameEndScreen
          goToLeaderBoard={this.goToLeaderBoard}
          bonus_score={this.state.bonus_score}
          score={this.state.score}
          maxscore={this.state.maxscore}
          contest_img={this.state.contest_img}
          contest_name={this.state.contest_name}
          goToHome={this.goBack}
          retry={this.retry}
        />
      );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedin,
    doesAccountExist: state.auth.doesAccountExist,
    contest: state.contest.contest_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScores: (uid, domain) => dispatch(getScores(uid, domain)),
    getTop10: (dc_id) => dispatch(getTop10(dc_id)),
    verifyUser: (uid) => dispatch(verifyUser(uid)),
    getContests: (domain_name) => dispatch(getContests(domain_name)),
    getRank: (cid, score) => dispatch(getRank(cid, score))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Games);
