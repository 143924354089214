import * as actiontypes from './../actions/actiontypes'
const initState = {
    scores_data:null,
    top_10_scores: [],
    curr_rank: null
}
const ScoreReducer = (state = initState, action) => {
    switch(action.type){
        case actiontypes.GET_TOP_10_SCORES:
            return {...state, scores_data: action.payload}
        case actiontypes.GET_TOP_10_LEADERBOARD:
            return {...state, top_10_scores: action.payload}
        case actiontypes.GET_RANK:
            return {...state, curr_rank: action.payload}
        case actiontypes.RESET_RANK:
            return {...state, curr_rank: null}
        default:
            return {...state}
    }
}

export default ScoreReducer;