import React from 'react'
import './GameEndScreen.css'
import duck from './../../assets/images/duck.jpg'
import trophylogo from './../../assets/images/Trophy.svg';
import CombinedHeaderandSidebar from '../CombinedHeaderandSidebar/CombinedHeaderandSidebar';
import { FormattedMessage } from 'react-intl';

class GameEndScreen extends React.Component{
   
    componentDidMount(){
        window.scrollTo(0,0)
    }

    render(){
        return(
    <div className="main-end-container">
            <CombinedHeaderandSidebar />
        <div className="end-game-container">
            <div className="end-game">
            <div className="trophy-logo">
                <img src={trophylogo} alt="" />
            </div>
            <div className="game-end-header">
                <span>
                    <FormattedMessage id="Game Over!" defaultMessage="Game Over!" /> 
                </span>
            </div>
            <div className="bonus-points">
                <span><FormattedMessage id="Bonus Score" defaultMessage="Bonus Score" />:</span>
                <span>{this.props.bonus_score}</span>
            </div>
            <div className="your-total-score">
                <span><FormattedMessage id="Your Total Score" defaultMessage="Your Total Score" />:</span>
                <span>{this.props.score + this.props.bonus_score}</span>
            </div>
            <div className="end-game-buttons">
                <span onClick={this.props.goToLeaderBoard}><FormattedMessage id="Leaderboard" defaultMessage="Leaderboard" /></span>
                <span onClick={this.props.retry}>
                <FormattedMessage id="Retry" defaultMessage="Retry" />
                </span>
               
            </div>
            <div className="congrats-end-game">
                    {(this.props.score + this.props.bonus_score > this.props.maxscore) && (this.props.score + this.props.bonus_score > 0) ?
                        <> 
                        <span><FormattedMessage id="Congratulations!" defaultMessage="Congratulations!" /></span>
                        <span><FormattedMessage id="You are now eligible for" defaultMessage="You are now eligible for" />{" "}{this.props.contest_name}.</span>
                        <span><FormattedMessage id="We will inform you if you win." defaultMessage="We will inform you if you win." /></span> 
                        </>
                        : 
                        <>
                        <span><FormattedMessage id="Sorry!" defaultMessage="Sorry!" /></span>
                        <span><FormattedMessage id="You are not eligible for" defaultMessage="You are not eligible for" />{" "}{this.props.contest_name}.</span>
                        <span><FormattedMessage id="Better luck next time." defaultMessage="Better luck next time." /></span>
                        </>
                    }
            </div>
                <div className="end-game-image">
                    {this.props.score + this.props.bonus_score > this.props.maxscore ? <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${this.props.contest_img}`} alt="..." /> 
                    : <img src={duck} alt="" />}
                </div>
               
            </div>
        </div>
            
          
        </div>
            
        )
    }
}

export default GameEndScreen;