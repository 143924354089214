import {getSubdomain} from './domain_config'

export let BASE_API_URL = '';
export const VERIFY_UID_URL = 'https://de-mcb-api.sam-media.com/mcb/api/v2/validate-access/sam';
export const VERIFY_MSISDN_URL = 'https://de-mcb-api.sam-media.com/api/v2/validate-access/sam'
export const ANALYTICS_URL = 'https://de-pacman.sam-media.com/api/v2/mstore'
 if (getSubdomain() === 'localhost'){
     BASE_API_URL = 'http://localhost:4000/api';
 }
 else {
     BASE_API_URL = `${window.location.origin}/api`
 }
 
