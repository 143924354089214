import React from 'react'
import './Alert.css'
import {connect} from 'react-redux'
import {hide_alerts} from './../../redux/actions/action_creators/alert_actions'
import { FormattedMessage } from 'react-intl'

class Alert extends React.Component{

    componentDidUpdate(){
        setTimeout(() => {
            this.props.hide()
        }, 2000)
    }

    render(){
        let className = "custom-alert"
        let Style;
        if(this.props.status){
            className = "custom-alert show-alert"
        }
        if (this.props.type === 'error'){
            if(this.props.position === 'top') Style = {backgroundColor: 'darkred', top: '0'}
            else Style = {backgroundColor: 'darkred', top: '56px'}
        }
        else if (this.props.type === 'success'){
            if(this.props.position === 'top') Style = {backgroundColor: 'darkgreen', top: '0'}
            else Style = {backgroundColor: 'darkgreen', top: '56px'}
        }
        else Style = {backgroundColor: 'black', top: '0px'}
        return(
            <div className={className} style={Style}>
                {this.props.msg.length !== 0 ? <FormattedMessage id={this.props.msg} defaultMessage={this.props.msg} /> : 'Default Alert'}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        msg: state.alert.msg,
        type: state.alert.type,
        position: state.alert.position,
        status: state.alert.status
    }
}

const mapDispatchToProps = dispatch => {
    return{
        hide: () => dispatch(hide_alerts())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);