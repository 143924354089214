import React from 'react'
import game from './../../assets/images/home/play.svg'
import select from './../../assets/images/home/selectgame.svg'
import gift from './../../assets/images/home/claim.svg'
import {FormattedMessage} from 'react-intl'
import './Instructions.css'

class Instructions extends React.Component {
    render(){
        return( 
            <div className="gameplay-top">
            <div className="gameplay-inst">
                <div className="inst-image">
                    <img src={select} alt="1" />
                    <div>
                        <FormattedMessage id="Choose a prize" defaultMessage="Choose a prize" />
                    </div>
                    <div className="ticker-inst">
                        1
                    </div>
                </div>
                <div className="inst-image">
                    <img src={game} alt="2" />
                    <div>
                        <FormattedMessage id="Play and beat the best score" defaultMessage="Play &amp; beat the best score" />
                    </div>
                    <div className="ticker-inst">
                       2
                    </div>
                </div>
                <div className="inst-image">
                    <img src={gift} alt="3" />
                    <div>
                        <FormattedMessage id="Win the prize!" defaultMessage="Win the prize!" />
                    </div>
                    <div className="ticker-inst">
                       3
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default Instructions;