import React from 'react'
import './Home.css'
import {connect} from 'react-redux'
import Card from './../../components/Card/Card'
import CombinedHeaderandSidebar from '../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar'
import Loader from '../../components/Loader/Loader'
import Instructions from '../../components/Instructions/Instructions'
import Carousel from '../../components/Carousel/Carousel'
import { sendData } from '../../analytics/senddata'

class Home extends React.Component{
    
    state = {
        timeout: false
    }

    componentDidMount(){
        window.scrollTo(0,0);
        sendData('','','','Viewed',{page: 'Homepage'});
    }

    onClickHandler = (e) => {
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true){
            this.props.history.push("/login")
        }
        else this.props.history.push({
            pathname: '/games',
            state: {index: e.target.id}
        })
    }
    
    render(){
        if(this.props.contest_data.length === 0){
            return(
                <>
                <CombinedHeaderandSidebar />
                <Loader name="contests" />    
                </>
            )
        }
        else return(
        <div className="main-home-container">
            <CombinedHeaderandSidebar />
          
            <div className="container home-page">
                    <Carousel onClick={this.onClickHandler} />
                    <Instructions />
                    <div className="contest-cards">
                        {this.props.contest_data.map((item) => {
                            return(
                                <Card key={item.id} id={item.id} title={item.prize_name} maxscore={item.maxscore !== null && item.maxscore > 0 ? item.maxscore : 0} img={item.prize_img} onClick={this.onClickHandler} end_date={new Date(item.end_date)} />
                            )
                        })}           
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        contest_data: state.contest.contest_data,
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
    }
}

export default connect(mapStateToProps, null)(Home);