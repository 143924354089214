import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'

export const getQuestions = (lang) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/quiz/questions?lang=en`)
        .then((response) => {
            if(response.data.length > 0){
                dispatch(LoadQuestionToRedux(response.data, true))
            }
        })
        .catch((e) => console.log(e))
    }
}

const LoadQuestionToRedux = (data, loaded) => {
    return{
        type: actiontypes.GET_QUESTIONS,
        loaded: loaded,
        payload: data
    }
}
