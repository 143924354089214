import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Carousel.css";
import Slider from "react-slick";
import Counter from "../Counter/Counter";
// import { CarouselContests } from "./CarouselContests";
import { isMobile } from "./../../utils/helper";
// import { config } from "./../../utils/domain_config";
import {FormattedMessage} from 'react-intl'
class Carousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      appendDots: (dots) => (
        <div
          style={
            isMobile.any()
              ? {
                  // bottom: '-40px',
                  display: "flex",
                  justifyContent: "center",
                  paddingRight: "20px",
                }
              : {}
          }
        >
          <ul> {dots} </ul>
        </div>
      ),
    };
    return (
      <div className="carousel-card-wrapper">
        <Slider {...settings}>
          {this.props.contest_data.slice(0,4).map((item, index) => {
              return (
                <div key={index}>
                  <div className="carousel-card-main">
                    <div className="carousel-content-img">
                      <img
                        src={`https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${item.prize_img}`}
                        alt=""
                      />
                    </div>
                    <div className="carousel-content-text">
                      <div className="carousel-title">{item.prize_name}</div>
                      <div className="carousel-score-to-win">
                        <span className="part-a-score">
                          <span>
                            <i className="fas fa-star"></i>
                          </span>{" "}
                          <FormattedMessage id="Score to Beat" defaultMessage="Score to Beat" />:
                        </span>
                        <span className="part-b-score">
                          {item.maxscore !== null && item.maxscore > 0
                            ? item.maxscore
                            : 0}{" "}
                          <FormattedMessage id="pts" defaultMessage="pts" />
                        </span>
                      </div>
                      <div className="carousel-counter">
                        <Counter
                          styletype="style1"
                          expiryDate={new Date(item.end_date)}
                        />
                      </div>
                      <div
                        className="carousel-button"
                        id={item.id}
                        onClick={this.props.onClick}
                      >
                        <span className="play-now">
                            <FormattedMessage id="Play Now" defaultMessage="Play Now" />
                        </span>
                        <span className="play-icon">
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contest_data: state.contest.contest_data,
  };
};

export default connect(mapStateToProps, null)(withRouter(Carousel));
