import React from 'react'
import {connect} from 'react-redux'
import './FAQ.css'
import CombinedHeaderandSidebar from '../../../components/CombinedHeaderandSidebar/CombinedHeaderandSidebar'
import {faq_data} from './faqdata'
import { FormattedMessage } from 'react-intl'
import { sendData } from '../../../analytics/senddata'
import { config } from '../../../utils/domain_config'

class FAQ extends React.Component{
    componentDidMount(){
        window.scrollTo(0,0)
        sendData('','','','Viewed',{page: 'FAQ Page'})
    }
    componentDidUpdate(){
        if(sessionStorage.getItem('accountexist') === null && this.props.isLoggedIn !== true){
            this.props.history.push("/")
        }
    }
    
    render(){
        if(config.subDomain === "gr-mobimilia"){
            return(
                <div className="faq-main">
                    <CombinedHeaderandSidebar />
                    <div className="faq-heading-custom">
                        Συχνές Ερωτήσεις
                    </div>
                    <div className="faq-container">
                        <div className="faq-answer">
                            <p>Πενταψήφιος Κωδικός: 54003 ή 54099</p>
                        </div>    
                        <div className="faq-question">
                            ΣΧΕΤΙΚΑ ΜΕ ΤΗ ΣΥΝΔΡΟΜΗ ΣΑΣ
                        </div>
                        <div className="faq-answer">
                            <p>Η FUNIWIN παρέχει ψυχαγωγικές υπηρεσίες που συνίστανται στην συμμετοχή σε κουϊζ/διαγωνιστικό παιχνίδι διεκδίκησης επάθλου.</p>
                            <p>Μετά από έγκυρη εγγραφή και πληρωμή, μπορείτε να αποκτήσετε πρόσβαση στον εβδομαδιαίο κύκλο ερωτήσεων/κουίζ της FUNIWIN.</p>
                            <p>Τα μέλη της FUNIWIN λαμβάνουν τον προσωπικό τους σύνδεσμο κάθε μήνα, με τον οποίο μπορούν να δοκιμάσουν τις γνώσεις και την ταχύτητά τους απαντώντας σε εναν κύκλο ερωτήσεων. Στο τέλος της διαγωνιστικής περιόδου ο πλέον γρήγορος και σωστός παίκτης κερδίζει το έπαθλο για το οποίο δήλωσε συμμετοχή.</p>
                            <p>Το FUNIWIN είναι μια μηνιαία συνδρομή. Θα λαμβάνετε δώδεκα (12) κατά την λήψη χρεώσιμα μηνύματα, αξίας 2,29€ έκαστο ή σαρανταοχτώ (48) κατά την λήψη χρεώσιμα μηνύματα αξίας 0,57€, εβδομαδιαίως, συνολική χρέωση: 27,48€ ανά μήνα. <a href="https://c1.ouisys.com/content/el/psms-table/?country=gr&amp;service=funiwin&amp;_gl=1*tix7hm*_gcl_au*NDA3OTQzODg2LjE3MDY1ODcxNzI.">Τελική Τιμή</a> Μηνύματος</p>
                            <p>Η εβδομαδιαία χρέωση λαμβάνεται αυτόματα μέσω του λογαριασμού του τηλεφώνου σας.</p>
                            <p>Για να χρησιμοποιήσετε μία από τις υπηρεσίες FUNIWIN, πρέπει να είστε 18 ετών και άνω και να έχετε την άδεια του ατόμου που πληρώνει τον λογαριασμό του κινητού τηλεφώνου.</p>
                        </div>
                        <div className="faq-question">
                            ΕΠΙΚΟΙΝΩΝΙΑ
                        </div>
                        <div className="faq-answer">
                            <p>Η Υπηρεσία FUNIWIN, στην Ελλάδα υποστηρίζεται από την MOBIMILIA BV μέσω των 5ψηφίων κωδικών της και της τεχνικής υποδομής της.</p>
                            <p>Για όλες τις ερωτήσεις αναφορικά με τη συνδρομή σας, επικοινωνήστε με το τμήμα εξυπηρέτησης πελατών μας στο τηλ. 2112348102 ή επικοινωνήστε στην ηλεκτρονική διεύθυνση mobimilia.gr@cchelp.co</p>
                            <p>Εάν επιθυμείτε να διακόψετε τη συνδρομή σας από αυτήν την υπηρεσία, στείλτε μήνυμα από το κινητό σας με την λέξη STOP WN  στον κωδικό πενταψήφιο αριθμό 54003 ή 54099 όπου είχατε εγγραφεί</p>
                            <p>Το μήνυμα διαγραφής που αποστέλλει ο συνδρομητής στον πενταψήφιο κωδικό 54003 ή 54099 έχει κόστος απλού τυπικού μηνύματος, που διαφοροποιείται ανά πάροχο κινητής τηλεφωνίας. Η επιβεβαίωση διαγραφής αποστέλλεται στον συνδρομητή δωρεάν από τον κωδικό 54784</p>
                        </div>
                    </div>
                </div>
            )
        }    
        else {
            return(
                <div className="faq-main">
                <CombinedHeaderandSidebar />
                <div className="faq-heading-custom">
                    <FormattedMessage id="Frequently Asked Questions" defaultMessage="Frequently Asked Questions" />
                </div>
                {faq_data.map((item, index) => {
                    return(
                        <div key={index} className="faq-container">
                            <div className="faq-question">
                                Q. <FormattedMessage id={`q${item.id}`} defaultMessage={item.question} />
                            </div>
                            <div className="faq-answer">
                                {typeof(item.answer) !== "string" ? 
                                    item.answer.map((x,idx) => {
                                        return(
                                            <div key={idx}>
                                                <FormattedMessage id={`a1${idx}`} defaultMessage={x} />
                                            </div>
                                        )
                                    })
                                : <FormattedMessage id={`a${item.id}`} defaultMessage={item.answer} />}
                            </div>
                        </div>
                    )
                })}
                </div>
            )
        }
    }
}


const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedin,
        doesAccountExist: state.auth.doesAccountExist,
    }
}

export default connect(mapStateToProps, null)(FAQ);