import { getSessionCookie } from './sessionid';
import { config, getSubdomain } from './../utils/domain_config';
import { ANALYTICS_URL } from './../utils/constants'
export const sendData = (cName, cCategory, cType, uType, additionalParams) => {
    const sessionId = getSessionCookie();
    const subDomain = config.subDomain;
    const args = {
        url: window.location.href,
        lang: config.lang
    }
    const rockmanId =  sessionStorage.getItem('rockmanId')
    if( rockmanId && rockmanId.length > 0 ){
        const data = {
            r: rockmanId,
            portal_session_id: sessionId,
            d: [
                {
                    t: "content_usage",
                    a: {
                        content_name: cName,
                        content_category: cCategory,
                        content_type: cType,
                        usage_type: uType,
                        args: {...args, ...additionalParams},
                        product_name: 'funiwin',
                        subdomain: subDomain
                    }
                }
            ]
        };
        if(getSubdomain() === 'localhost'){
            console.log(data);
            return;
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', ANALYTICS_URL, true);
        xhr.send(JSON.stringify(data));
    }
    return;
}
