import React from 'react'
import './ContestCard.css'
import {connect} from 'react-redux'
import { FormattedMessage } from 'react-intl'

class ContestCard extends React.Component{    

    render(){
        let {name, img, score} = this.props
        return(
           <div className="game-contest-card">
               <div className="game-contest-card-inner">
                    <div className="game-contest-card-inner-img">
                        {img.length !== 0 ? <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/funiwin/${img}`} alt="..." /> : null}
                    </div>
                    <div className="game-contest-card-inner-text">
                        <div>{name}</div>
                        <div>
                            <FormattedMessage id="Best Score" defaultMessage="Best Score" />: {score === null ? 0 : score}
                        </div>
                    </div>
               </div>
           </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        contest: state.contest.contest_data
    }
}

export default connect(mapStateToProps, null)(ContestCard);