import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import './GamePlay.css'
import {getRandomElements, ShuffleArray} from './../../utils/helper'
import Timer from '../Timer/Timer'
import { FormattedMessage } from 'react-intl'

class GamePlay extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            questions: [],
            answers: [],
            options: [],
            correct_ans: [],
            incorrect_ans: [],
            score: 0,
        }
        this.getTimeRemaining = React.createRef()
    }

    componentDidMount(){
            window.scrollTo(0,0)
            let games_data = getRandomElements(this.props.games, this.props.level * this.props.qperlevel)
            this.setState({
                questions: games_data.map((item) => (item.questions)),
                answers: games_data.map((item) => (item.answers)),
                options: games_data.map((item) => (ShuffleArray(item.options)))
            })
    }

    onClickHandler = (e) => {
        
        let {answers} = this.state
        let index = this.props.index
        let length = answers[index].length
        let id = e.target.id
        let baseImage = e.target.childNodes[0]
        let tickImage = e.target.childNodes[1]
        if(answers[index].indexOf(id) !== -1){
            tickImage.classList.add('ticked')
            e.target.classList.add('disable-click')
            this.setState((prevState) => ({score: prevState.score + Math.floor(100/length)}))
            this.tickImageDelay = setTimeout(() => {
                this.setState((prevState) => ({correct_ans: prevState.correct_ans.concat(id)}))
            }, 500)
        }

        else{
            let attemptleft = document.getElementsByClassName('gameplay-error')
            let overlay = document.getElementsByClassName('gameplay-error-overlay')
            attemptleft = [...attemptleft]
            overlay = [...overlay]
            overlay = overlay[0]
            attemptleft.map((item) => (item.classList.remove('hideattempt')))
            baseImage.classList.add('shake')
            overlay.classList.add('show-overlay')
            this.setState((prevState) => ({score: prevState.score - 10, incorrect_ans: prevState.incorrect_ans.concat(id)}))
            this.shakeDelay = setTimeout(() => {
                baseImage.classList.remove('shake')
            }, 500)
            this.attemptleftDelay = setTimeout(() => {
                attemptleft.map((item) => (item.classList.add('hideattempt')))
                overlay.classList.remove('show-overlay')
            }, 1300)
        }
    }

    componentDidUpdate(){
        if(this.state.questions.length === 0 || this.state.options.length === 0 || this.state.answers.length === 0) this.props.history.push('/')
        else{
            if(this.state.incorrect_ans.length === 5){
                let images = document.getElementsByClassName('image-container')
                images = [...images]
                images.map((item) => (item.classList.add('disable-click')))
                this.props.onGameEnd(this.state.score, 0)
            }
            if(this.props.index === this.state.questions.length - 1 && this.state.correct_ans.length === this.state.answers[this.props.index].length){
                let images = document.getElementsByClassName('image-container')
                images = [...images]
                images.map((item) => (item.classList.add('disable-click')))
                let time_left_score = Math.floor ((this.getTimeRemaining.current.state.time / (Math.floor(this.state.questions.length/5) * 60)) * 5000)
                this.props.onGameEnd(this.state.score, time_left_score)  

            }
            else if(this.state.correct_ans.length === this.state.answers[this.props.index].length){
                let images = document.getElementsByClassName('image-container')
                images = [...images]
                images.map((item) => (item.classList.remove('disable-click')))
                this.setState({correct_ans: []})
                this.props.changeQuestion()
            }
        }
    }

    componentWillUnmount(){
        clearTimeout(this.shakeDelay)
        clearTimeout(this.tickImageDelay)
        clearTimeout(this.attemptleftDelay)
    }

    render(){
        let {questions, options} = this.state
        let index = this.props.index
        let images = options[index]
        let width = ( (index + 1) / questions.length) * 100
        if( (questions.length === 0 && options.length === 0) || !this.props.start ) return <div className="game-loader" />;
        return(
            <>
            
            <div className="timer-container-game">
            <Timer mins={ Math.floor(questions.length/5)} ref={this.getTimeRemaining} />
            </div>
            <div className="gameplay-error hideattempt">
                            <div>{5 - this.state.incorrect_ans.length}</div>
                            <div>
                                <FormattedMessage id="Attempts" defaultMessage="Attempts" />
                            </div>
                            <div>
                                <FormattedMessage id="Left" defaultMessage="Left" />
                            </div>

                        </div>
            <div className="questions">
                {questions[index]}
            </div>
            {!this.props.changing ? 
            <>
            <div className="image-gallery">
                <div id={images[0]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[0]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[1]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[1]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[2]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[2]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
            </div>

            <div className="image-gallery">
                <div id={images[3]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[3]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[4]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[4]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[5]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[5]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
            </div>

            <div className="image-gallery">
                <div id={images[6]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[6]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[7]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[7]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[8]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[8]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
            </div>

            <div className="image-gallery">
                <div id={images[9]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[9]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[10]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[10]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
                <div id={images[11]} className="image-container" onClick={this.onClickHandler}>
                    <img src={`https://funiwin.s3-eu-west-1.amazonaws.com/img/questions/${images[11]}`} alt="" />
                    <img src={require('./../../assets/images/tickimage.jpg')} alt="..." className="tick-image" />
                </div>
            </div>
            </>
            :
            <div className="image-changing-game">
                <i className="fas fa-gamepad fa-pulse icon" style={{color: '#479dd2', fontSize: '28px'}}></i>
                <div>
                { this.props.index !==0 && (this.props.index % this.props.qperlevel === 0) ? <> <FormattedMessage id="You've reached level " defaultMessage="You've reached level " /> <span>{(this.props.index / this.props.qperlevel) + 1}</span> </> : null}
                </div>
            </div>}
            

            <div className="custom-progress-bar">
                <span style={{width: `${width}%`}} />
            </div>
            <div className="scoreboard-quiz">
                <span><FormattedMessage id="Score" defaultMessage="Score" />:</span> {this.state.score}
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        games: state.question.all_questions
    }
}

export default connect(mapStateToProps, null)(withRouter(GamePlay));