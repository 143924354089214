import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js'
import '@fortawesome/fontawesome-free/css/all.min.css'
import {auth} from './redux/actions/action_creators/auth_actions'
import {verifyUser} from './redux/actions/action_creators/account_actions'
import {getContests} from './redux/actions/action_creators/contest_actions'
import {getScores} from './redux/actions/action_creators/score_actions'
import {getQuestions} from './redux/actions/action_creators/question_actions'
import {getFeedback} from './redux/actions/action_creators/feedback_actions'
import {config} from './utils/domain_config'
import './App.css'
import Login from './containers/Login/Login';
import Splash from './containers/Splash/Splash';
import Account from './containers/Account/Account';
import Home from './containers/Home/Home';
import Games from './containers/Games/Games';
import Profile from './containers/Profile/Profile';
import Scoreboard from './containers/Scoreboard/Scoreboard';
import FAQ from './containers/Static Pages/FAQ/FAQ';
import Contact from './containers/Static Pages/Contact/Contact';
import Orientation from './containers/Orientation/Orientation';
import LeaderBoard from './containers/LeaderBoard/LeaderBoard';

class App extends React.Component{
  componentDidMount(){
    const url = new URL(window.location.href);
    const uid_from_url = url.searchParams.get("uid") || sessionStorage.getItem("uid") || url.searchParams.get("rid");
    const msisdn_from_url = url.searchParams.get("msisdn");
    const staging = url.searchParams.get("is_staging");
    this.props.authenticate({
      uid: uid_from_url,
      msisdn: msisdn_from_url,
      staging: staging
    });
    if(uid_from_url){
      this.props.verifyUser(uid_from_url);
      this.props.getScores(uid_from_url,config.subDomain);
      this.props.getFeedback(uid_from_url)
    }
    this.props.getContests(config.subDomain);
    this.props.getQuestions(config.lang)
  }
  render(){
    return(
      <BrowserRouter>
        <div className="App"> 
        <Switch>
            <Route exact path="/" component={Splash} />
            <Route path="/orientation" component={Orientation} />
            <Route path="/login" component={Login} />
            <Route path="/account" component={Account} />
            <Route path="/home" component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/games" component={Games} />
            <Route path="/scores" component={Scoreboard} />
            <Route path="/faqs" component={FAQ} />
            <Route path="/contact" component={Contact} />
            <Route path="/leaderboard" component={LeaderBoard}/>
            <Route component={Splash} />
        </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return{
    authenticate: (uid) => dispatch(auth(uid)),
    verifyUser: (uid) => dispatch(verifyUser(uid)),
    getScores: (uid,domain) => dispatch(getScores(uid,domain)),
    getContests: (domain_name) => dispatch(getContests(domain_name)),
    getQuestions: (lang) => dispatch(getQuestions(lang)),
    getFeedback: (uid) => dispatch(getFeedback(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);