import * as actiontypes from './../actiontypes'

export const show_alerts = (data) => {
    return{
        type: actiontypes.SHOW_ALERT,
        msgtype: data.type,
        msg: data.msg,
        position: data.position
    }
}

export const hide_alerts = () => {
    return{
        type: actiontypes.HIDE_ALERT
    }
}