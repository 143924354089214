import * as actiontypes from './../actiontypes'
import axios from 'axios'
import {BASE_API_URL} from './../../../utils/constants'

export const getContests = (domain_name) => {
    return dispatch => {
        axios.get(`${BASE_API_URL}/contest/getContests?domain=${domain_name}&web=${true}`)
        .then((response) => {
            if(response.data.length > 0){
                dispatch(getContestsInfo(response.data))
            }
        })
        .catch((e) => console.log(e))
    }
}

const getContestsInfo = (data) => {
    return{
        type: actiontypes.GET_CONTESTS,
        payload: data
    }
}
